import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/faq.css"; // Using the same styling as the FAQ page

const QuickBooksDisconnected = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/");
  };

  return (
    <div className="faq-container">
      <header className="faq-header">
        <button className="back-button" onClick={handleBackClick}>
          ← Back
        </button>
        <div className="logo-container">
          <a href="/">
            <img
              src="./eblogo_2.png"
              alt="ExpenseBot Logo"
              className="faq-logo"
            />
          </a>
        </div>
        <div className="faq-title-container">
          <h1 className="faq-title">QuickBooks Disconnected</h1>
        </div>
      </header>

      <section className="section">
        <h2 className="section-header">QuickBooks Disconnected</h2>
        <p className="styled-paragraph">
          Your QuickBooks integration has been disconnected. You will no longer
          be able to submit expense reports directly to your QuickBooks account
          from ExpenseBot.
        </p>
        <p className="styled-paragraph">
          If you’d like to re-connect ExpenseBot and your QuickBooks account,
          return to <a href="https://www.expensebot.ai">www.expensebot.ai</a>.
        </p>
        <p className="styled-paragraph">
          For general ExpenseBot support, contact us at{" "}
          <a href="mailto:support@expensebot.ai">support@expensebot.ai</a>.
        </p>
      </section>
    </div>
  );
};

export default QuickBooksDisconnected;
