const uploadToGooglePhotos = async (photoBlob) => {
  const uploadWithRetry = async (attemptWithToken) => {
    const base64Data = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(photoBlob);
    });

    try {
      const response = await fetch("/api/upload-camera-photo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${attemptWithToken}`,
        },
        body: JSON.stringify({ photoData: base64Data }),
      });

      if (response.status === 401) {
        console.log("Token expired, refreshing...");
        const tokenResponse = await fetch("/api/get-new-token", {
          headers: {
            Authorization: `Bearer ${attemptWithToken}`,
          },
        });

        if (!tokenResponse.ok) {
          throw new Error("Failed to refresh token");
        }

        const { token: newToken } = await tokenResponse.json();
        console.log("Got new token, retrying upload...");
        localStorage.setItem("token", newToken);
        return uploadWithRetry(newToken);
      }

      // Add specific error handling for Google Photos issues
      if (!response.ok) {
        const errorData = await response.json();

        // Match the same error conditions from your endpoint
        if (response.status === 403) {
          throw new Error(
            "To use this feature, please ensure Google Photos is properly set up:\n\n" +
              "1. Install Google Photos from your device's app store\n" +
              "2. Open Google Photos and sign in\n" +
              "3. Go to Google Photos settings\n" +
              "4. Enable 'Backup & Sync'\n" +
              "5. Allow backup over cellular data (recommended)\n\n" +
              "Your photos will be available after syncing completes."
          );
        } else if (response.status === 404) {
          throw new Error(
            "No photos found. Please install Google Photos, enable 'Backup & Sync' in settings, and wait for your photos to sync."
          );
        }

        throw new Error(errorData.error || "Failed to upload photo");
      }

      const { mediaItem } = await response.json();
      return mediaItem;
    } catch (error) {
      console.error("Upload error:", error);
      throw error;
    }
  };

  const token = localStorage.getItem("token");
  return uploadWithRetry(token);
};

export { uploadToGooglePhotos };
