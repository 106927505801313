import React, { useState, useEffect } from "react";
import "../styles/FirstTimeUser.css";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const MileageAllowanceInput = ({ value, onChange }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
      onChange(value);
    }
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      placeholder="eg. 0.65"
      className="currency-select"
      inputMode="decimal"
      pattern="[0-9]*\.?[0-9]*"
    />
  );
};

const FirstTimeUser = ({ user, setProcessInfo }) => {
  const [selectedMileageUnit, setSelectedMileageUnit] = useState("mi");
  const [mileageAllowance, setMileageAllowance] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(!user);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const currencyNames = {
    AED: "UAE Dirham",
    AUD: "Australian Dollar",
    BGN: "Bulgarian Lev",
    BRL: "Brazilian Real",
    CAD: "Canadian Dollar",
    CHF: "Swiss Franc",
    CNY: "Chinese Yuan",
    CZK: "Czech Koruna",
    DKK: "Danish Krone",
    EUR: "Euro",
    GBP: "British Pound",
    HKD: "Hong Kong Dollar",
    HUF: "Hungarian Forint",
    IDR: "Indonesian Rupiah",
    ILS: "Israeli Shekel",
    INR: "Indian Rupee",
    JPY: "Japanese Yen",
    KRW: "South Korean Won",
    MXN: "Mexican Peso",
    MYR: "Malaysian Ringgit",
    NOK: "Norwegian Krone",
    NZD: "New Zealand Dollar",
    PHP: "Philippine Peso",
    PLN: "Polish Złoty",
    RON: "Romanian Leu",
    RUB: "Russian Ruble",
    SEK: "Swedish Krona",
    SGD: "Singapore Dollar",
    THB: "Thai Baht",
    TRY: "Turkish Lira",
    USD: "US Dollar",
    ZAR: "South African Rand",
  };

  // In FirstTimeUser component
  useEffect(() => {
    const referralEmail = localStorage.getItem("referralEmail");
    if (referralEmail) {
      // Auto-populate the client into the accountant's list
      handleAddReferredClient(referralEmail);
      // Clear the stored referral
      localStorage.removeItem("referralEmail");
    }
  }, []);

  const handleAddReferredClient = async (clientEmail) => {
    try {
      const response = await axios.post(
        "/api/accountant/invite-client",
        {
          clientEmail,
          fromReferral: true, // Flag to indicate this came from a referral
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        // Maybe show a success message
        console.log("Successfully connected with referred client");
      }
    } catch (error) {
      console.error("Error connecting with referred client:", error);
    }
  };

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    // Get browser locale and set default currency
    const locale = navigator.language;
    const country = locale.split("-")[1] || "US";
    const currencyMap = {
      US: "USD",
      GB: "GBP",
      AU: "AUD",
      CA: "CAD",
      NZ: "NZD",
      EU: "EUR",
      JP: "JPY",
      CN: "CNY",
      SG: "SGD",
      CH: "CHF",
      BR: "BRL",
      RU: "RUB",
      IN: "INR",
      ZA: "ZAR",
      // Add more as needed
    };
    setSelectedCurrency(currencyMap[country] || "USD");
  }, []);

  const CurrencySelector = () => (
    <select
      value={selectedCurrency}
      onChange={(e) => setSelectedCurrency(e.target.value)}
      className="currency-select"
    >
      {Object.entries(currencyNames)
        .sort((a, b) => a[1].localeCompare(b[1])) // Sort by currency name
        .map(([code, name]) => (
          <option key={code} value={code}>
            {code} - {name}
          </option>
        ))}
    </select>
  );
  const MileageUnitSelector = () => (
    <select
      value={selectedMileageUnit}
      onChange={(e) => setSelectedMileageUnit(e.target.value)}
      className="currency-select"
    >
      <option value="mi">mi - Miles</option>
      <option value="km">km - Kilometers</option>
    </select>
  );

  const handleContinueWithoutPhoto = async () => {
    setProcessing(true);

    const token = localStorage.getItem("token");
    if (!token) return;

    try {
      const response = await axios.post(
        `/api/listPhotos`,
        {
          userSelectedPhotos: ["SKIP_PHOTOS"],
          homeCurrency: selectedCurrency,
          mileageUnit: selectedMileageUnit,
          mileageAllowance: mileageAllowance || 0, // Send 0 if empty
          skipPhotoProcessing: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      if (data.success) {
        setProcessInfo(data.processInfo);
        navigate("/process-info", { replace: true });
      }
    } catch (error) {
      let errorMessage = "An error occurred while fetching data.";
      let errorDetails = "Please try again";

      if (error.response) {
        errorMessage += ` Server responded with status ${error.response.status}.`;
        errorDetails = error.response.data.message || error.message;
      } else if (error.request) {
        errorMessage += " No response received from the server.";
        errorDetails = "Please check your network connection and try again.";
      } else {
        errorDetails = error.message;
      }

      setError(`${errorMessage} Details: ${errorDetails}`);
    } finally {
      setProcessing(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="firsttime-container">
      <header className="header">
        <h2 className="main-header">Welcome to ExpenseBot!</h2>
        <p className="instructions">
          Customize your settings below to get started.
        </p>
      </header>

      <main className="form-container">
        <div className="image-container_ft">
          <img
            src="./selectphoto.png"
            alt="Select a receipt from your camera roll"
            className="receipt-demo-image"
          />
        </div>

        <div className="form-section">
          <div className="form-group">
            <label htmlFor="currency">Home Currency</label>
            <CurrencySelector />
          </div>
          <div className="form-group">
            <label htmlFor="mileageUnit">Mileage Unit (mi/km)</label>
            <MileageUnitSelector />
          </div>
          <div className="form-group">
            <label htmlFor="mileageAllowance">
              Allowance per {selectedMileageUnit} (optional)
            </label>
            <MileageAllowanceInput
              value={mileageAllowance}
              onChange={setMileageAllowance}
            />
          </div>
        </div>

        <div className="call-to-action">
          <button
            className="start-button"
            onClick={handleContinueWithoutPhoto}
            disabled={processing}
          >
            {processing ? (
              <TailSpin color="#FFF" height={20} width={20} />
            ) : (
              "Continue"
            )}
          </button>

          {error && <p className="error-text">{error}</p>}
        </div>
      </main>

      <footer className="footerfirst">
        <div className="footer-content">
          <img
            src="./eblogo_2.png"
            alt="ExpenseBot logo"
            className="footer-logo"
          />
          <p className="footer-text">Powered by ExpenseBot</p>
        </div>
      </footer>
    </div>
  );
};

export default FirstTimeUser;
