import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/faq.css";

const FAQPage = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("quickstart");

  const handleBackClick = () => {
    navigate("/");
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setActiveSection(sectionId);
  };

  return (
    <div className="faq-container">
      <header className="faq-header">
        <button className="back-button" onClick={handleBackClick}>
          ← Back
        </button>
        <div className="logo-container">
          <a href="/">
            <img
              src="./eblogo_2.png"
              alt="ExpenseBot Logo"
              className="faq-logo"
            />
          </a>
        </div>
        <div className="faq-title-container">
          <h1 className="faq-title">ExpenseBot Guide & FAQ</h1>
        </div>
      </header>

      {/* YouTube Links */}
      <div className="faq-nav">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <a
            href="https://www.youtube.com/@expensebot"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-item"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              maxWidth: "400px",
            }}
          >
            <i
              className="fab fa-youtube"
              style={{ fontSize: "24px", color: "#ff0000" }}
            ></i>
            Subscribe to ExpenseBot Channel
          </a>

          <a
            href="https://www.youtube.com/playlist?list=PLjwcWbInP0mIQv7xhos1-74t7pDz19lD7"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-item"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              maxWidth: "400px",
            }}
          >
            <i
              className="fab fa-youtube"
              style={{ fontSize: "24px", color: "#ff0000" }}
            ></i>
            Watch Getting Started Playlist
          </a>
        </div>
      </div>

      {/* Navigation Menu Yes*/}
      <nav className="faq-nav">
        <button
          className={`nav-item ${
            activeSection === "quickstart" ? "active" : ""
          }`}
          onClick={() => scrollToSection("quickstart")}
        >
          Quick Start
        </button>

        <button
          className={`nav-item ${activeSection === "faq" ? "active" : ""}`}
          onClick={() => scrollToSection("faq")}
        >
          FAQ
        </button>
        <button
          className={`nav-item ${activeSection === "tips" ? "active" : ""}`}
          onClick={() => scrollToSection("tips")}
        >
          Tips & Best Practices
        </button>
      </nav>

      {/* Quick Start Guide Section */}
      <section id="quickstart" className="section">
        <h2 className="section-header">Quick Start Guide</h2>

        {/* Required App Setup - NEW LOCATION */}
        <div className="subsection">
          <h3 className="subsection-title">Required App Setup</h3>
          <p className="faq-answer" style={{ marginBottom: "20px" }}>
            ExpenseBot works exclusively with Google Photos. You must have the
            Google Photos app installed and automatic photo syncing enabled on
            your device.
          </p>
          <div className="step-list">
            <div className="step-item">
              <span className="step-number">1</span>
              <div className="step-content">
                Download and set up Google Photos
                <div style={{ marginTop: "10px" }}>
                  <a
                    href="https://apps.apple.com/app/google-photos/id962194608"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-item"
                    style={{
                      display: "inline-block",
                      padding: "5px 15px",
                      marginRight: "10px",
                    }}
                  >
                    Download Google Photos for iPhone
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.photos"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-item"
                    style={{ display: "inline-block", padding: "5px 15px" }}
                  >
                    Download Google Photos for Android
                  </a>
                </div>
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">2</span>
              <div className="step-content">
                Enable automatic backup of Photos in the Google Photos app
                settings
                <div style={{ marginTop: "10px" }}>
                  <a
                    href="https://support.google.com/photos/answer/6193313?co=GENIE.Platform%3DiOS&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-item"
                    style={{
                      display: "inline-block",
                      padding: "5px 15px",
                      marginRight: "10px",
                    }}
                  >
                    iPhone Setup Guide
                  </a>
                  <a
                    href="https://support.google.com/photos/answer/6193313?co=GENIE.Platform%3DAndroid&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-item"
                    style={{ display: "inline-block", padding: "5px 15px" }}
                  >
                    Android Setup Guide
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="subsection">
          <h3 className="subsection-title">Getting Started</h3>
          <div className="step-list">
            <div className="step-item">
              <span className="step-number">1</span>
              <div className="step-content">
                Click "Get Photos from Google Photos" and select a few images of
                receipts. TIP : When selecting photos choose the search icon and
                type in "Receipts" and it will show all your photos of receipts.
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">2</span>
              <div className="step-content">
                Wait for processing completion (you'll receive an email)
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">3</span>
              <div className="step-content">
                Click the "View Expense Spreadsheet" button to see them or click
                on the link in the email.
              </div>
            </div>
          </div>

          <div className="important-note">
            Important: Add info@expensebot.ai to your safe sender list to
            receive processing notifications
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="section">
        <h2 className="section-header">Frequently Asked Questions</h2>

        {/* General Questions */}
        <div className="subsection">
          <h3 className="subsection-title">General Questions</h3>
          <div className="faq-item">
            <p className="faq-question">How do I handle emailed receipts?</p>
            <p className="faq-answer">
              Simply take a screen capture of the receipt. You can then submit
              it using the "Find Receipts in Google Photos" button or by
              choosing the "Upload Receipt from Device" option—and that’s it!
            </p>
          </div>
          <div className="faq-item">
            <p className="faq-question">
              Does ExpenseBot handle duplicate receipts?
            </p>
            <p className="faq-answer">
              Yes! ExpenseBot automatically detects and eliminates duplicate
              receipts to keep your reports accurate.
            </p>
          </div>
          <div className="faq-item">
            <p className="faq-question">Can I connect QuickBooks?</p>
            <p className="faq-answer">
              Yes, you or your accounting professional can submit your reports
              to QuickBooks. For more information, see{" "}
              <a
                href="https://www.expensebot.ai/quickbooksintegration"
                target="_blank"
                rel="noopener noreferrer"
              >
                expensebot.ai/quickbooksintegration
              </a>
              .
            </p>
          </div>

          <div className="faq-item">
            <p className="faq-question">Do I need an app?</p>
            <p className="faq-answer">
              No, just sign in at https://www.expensebot.ai
            </p>
          </div>
        </div>

        {/* Privacy & Security */}
        <div className="subsection">
          <h3 className="subsection-title">Privacy & Security</h3>

          <div className="faq-item">
            <p className="faq-question">How does ExpenseBot handle my data?</p>
            <p className="faq-answer">
              ExpenseBot uses a <strong>privacy-first</strong> approach. Unlike
              most apps, ExpenseBot does not store your data. All data (photos
              and spreadsheets) is stored on your own Google Drive, accessible
              only by you.
            </p>
          </div>

          <div className="faq-item">
            <p className="faq-question">
              Can I reset my account or delete my data?
            </p>
            <p className="faq-answer">
              Yes, under <strong>Settings</strong>, choose{" "}
              <strong>Reset Account</strong> to delete the ExpenseBot folder on
              your Drive and start fresh. This won't affect your subscription
              status.
            </p>
          </div>
        </div>
      </section>

      {/* Tips & Best Practices Section */}
      <section id="tips" className="section">
        <h2 className="section-header">Tips & Best Practices</h2>

        {/* Receipt Tips */}
        <div className="tips-card">
          <h3 className="tips-card-title">Receipt Management</h3>
          <ul className="tips-list">
            <li className="tips-item">
              <i className="fas fa-trash-alt tips-icon"></i>
              <span className="tips-content">
                Once you submit a photo of a receipt to ExpenseBot, you can
                delete it from your camera roll
              </span>
            </li>
            <li className="tips-item">
              <i className="fas fa-receipt tips-icon"></i>
              <span className="tips-content">
                For restaurants receipts, include both the itemized receipt and
                credit card slip in the same photo to capture tax, tip and total
              </span>
            </li>
            <li className="tips-item">
              <i className="fas fa-pen tips-icon"></i>
              <span className="tips-content">
                Write Tag names directly on receipts before photographing for
                auto-classification
              </span>
            </li>
            <li className="tips-item">
              <i className="fas fa-mobile-alt tips-icon"></i>
              <span className="tips-content">
                Take screenshots of email receipts and submit them using the
                Find Receipts in Google Photos button
              </span>
            </li>
          </ul>
        </div>

        {/* Currency Tips */}
        <div className="tips-card">
          <h3 className="tips-card-title">Currency Management</h3>
          <ul className="tips-list">
            <li className="tips-item">
              <i className="fas fa-clock tips-icon"></i>
              <span className="tips-content">
                Wait for credit card statements before submitting foreign
                currency expenses and add converted amounts in the Func. Total
                column
              </span>
            </li>
            <li className="tips-item">
              <i className="fas fa-tags tips-icon"></i>
              <span className="tips-content">
                Create tags under the TAGS tab for different projects or trips.
                Use the Reimburse column (YES/NO) to control which expenses
                appear in reports
              </span>
            </li>
          </ul>
        </div>

        {/* Organization Tips */}
        <div className="tips-card">
          <h3 className="tips-card-title">Organization Best Practices</h3>
          <ul className="tips-list">
            <li className="tips-item">
              <i className="fas fa-user-plus tips-icon"></i>
              <div className="tips-content">
                Invite your assistant, accountant, or approver to view your
                reports
                <span className="menu-path">Reports → People with access</span>
              </div>
            </li>
            <li className="tips-item">
              <i className="fas fa-cog tips-icon"></i>
              <span className="tips-content">
                Customize "Tips for AI" rules in the spreadsheet under the
                Expense Account or Tags tabs
              </span>
            </li>
            <li className="tips-item">
              <i className="fas fa-highlighter tips-icon"></i>
              <span className="tips-content">
                Review yellow highlighted cells to check AI's suggestions
              </span>
            </li>
            <li className="tips-item">
              <i className="fas fa-undo-alt tips-icon"></i>
              <span className="tips-content">
                To resubmit expenses, remove the 'Submit Date' and 'Batch ID'
                entries from the main spreadsheet
              </span>
            </li>
            <li className="tips-item">
              <i className="fas fa-folder tips-icon"></i>
              <span className="tips-content">
                All ExpenseBot data is stored in your Google Drive "ExpenseBot
                Expenses" folder
              </span>
            </li>
          </ul>
        </div>

        <div className="important-note">
          Need help? Email support@expensebot.ai
        </div>
      </section>
    </div>
  );
};

export default FAQPage;
