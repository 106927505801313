import React, { useEffect, useState, useCallback } from "react";
import { isTokenExpired, refreshToken } from "./components/utils/tokenUtils";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import Home from "./components/Home";
import ProcessInfo from "./components/ProcessInfo";
import NoMatch from "./components/NoMatch";
import FAQPage from "./components/faq";
import TERMSPage from "./components/terms";
import PRIVACYPage from "./components/privacy";
import InviteAcceptance from "./components/InviteAcceptance";
import AccountantView from "./components/AccountantView";
import FirstTimeUser from "./components/FirstTimeUser";
import PricingPage from "./components/PricingPage";
import Viewers from "./components/ViewerList";
import AdminPortal from "./components/adminPortal";
import QuickbooksIntegration from "./components/quickbooksIntegration";
import QuickbooksDisconnect from "./components/quickbooksdisconnect";
import { QuickBooksProvider } from "./components/QuickbooksContext";

axios.defaults.validateStatus = function (status) {
  return (status >= 200 && status < 300) || status === 302;
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [processInfo, setProcessInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  /* const Cancel = () => {
    const navigate = useNavigate();

    useEffect(() => {
      navigate("/process-info");
    }, [navigate]);

    return null;
  }; */

  const Dashboard = () => {
    const navigate = useNavigate();

    useEffect(() => {
      navigate("/process-info");
    }, [navigate]);

    return null;
  };

  const fetchSessionInfo = useCallback(async () => {
    const token = localStorage.getItem("token");
    console.log("Fetching session info, token exists:", !!token);

    if (!token) {
      console.log("No token found in localStorage");
      setLoading(false);
      return;
    }

    try {
      console.log("Making session-info request...");
      const response = await axios.get("/api/session-info", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Session info response received:", response.status);
      const data = response.data;
      localStorage.setItem(
        "adminViewEnabled",
        data.roles?.accountant?.toString() || "false"
      );

      setLoggedIn(true);
      setUser(data);
      setProcessInfo(data.processInfo);
    } catch (error) {
      console.error("Error in fetchSessionInfo:", error.message);
      console.error("Full error:", error);

      // Only clear if it's an auth error
      if (error.response?.status === 401 || error.response?.status === 403) {
        console.log("Clearing auth state due to auth error");
        localStorage.removeItem("token");
        localStorage.removeItem("adminViewEnabled");
        setLoggedIn(false);
        setUser(null);
        setProcessInfo(null);
      } else {
        console.log("Non-auth error occurred, maintaining session state");
      }
    } finally {
      setLoading(false);
    }
  }, []);

  // Now define handleTokenRefresh since it depends on fetchSessionInfo
  const handleTokenRefresh = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;

      const isRefreshing = localStorage.getItem("isRefreshing");
      const lastRefresh = localStorage.getItem("lastTokenRefresh");
      const now = Date.now();

      // Prevent multiple simultaneous refreshes if another refresh is in progress
      if (
        isRefreshing ||
        (lastRefresh && now - parseInt(lastRefresh) < 60000)
      ) {
        return;
      }

      if (isTokenExpired(token)) {
        console.log("Token expired, attempting silent refresh...");
        localStorage.setItem("isRefreshing", "true");

        try {
          await refreshToken(); // Silent token refresh attempt
          localStorage.setItem("lastTokenRefresh", now.toString());
          await fetchSessionInfo(); // Refresh session info after successful token refresh
        } catch (error) {
          console.error("Silent token refresh failed:", error);

          // Force re-consent ONLY if it's a 401/403 => invalid or missing refresh token
          if (
            error.response?.status === 401 ||
            error.response?.status === 403
          ) {
            console.warn("Redirecting to auth with force re-consent...");
            window.location.href = `/api/auth?skipConsent=false`;
          } else {
            // Handle other errors (network failures, 500s, etc.) however you like
            // e.g., Show a notification, or do nothing.
          }
        } finally {
          localStorage.removeItem("isRefreshing");
        }
      }
    } catch (error) {
      console.error("Critical error during token refresh:", error);
      localStorage.removeItem("isRefreshing");
      localStorage.removeItem("token");
      setLoggedIn(false);
      setUser(null);
      setProcessInfo(null);
      navigate("/");
    }
  }, [fetchSessionInfo, navigate]);

  useEffect(() => {
    handleTokenRefresh();

    // Check token every 5 minutes
    const intervalId = setInterval(handleTokenRefresh, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [handleTokenRefresh]);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      console.group("---------- Visibility Debug -----------");
      console.log("Visibility changed to:", document.visibilityState);
      console.log("Current path:", window.location.pathname);
      console.log("Current URL:", window.location.href);
      console.log("Has token:", !!localStorage.getItem("token"));

      const token = localStorage.getItem("token");
      const isRefreshing = localStorage.getItem("isRefreshing");
      console.log("Is currently refreshing:", !!isRefreshing);

      if (token) {
        console.log("Token expired:", isTokenExpired(token));
        console.log(
          "Last token refresh:",
          localStorage.getItem("lastTokenRefresh")
        );
      }

      if (document.visibilityState === "visible") {
        console.log("App became visible, checking session...");

        if (token && !isTokenExpired(token)) {
          console.log("Token is still valid, skipping refresh");
          console.groupEnd();
          return; // No need to do anything
        }

        // Only try to refresh if token exists but is expired
        if (token && isTokenExpired(token)) {
          console.log("Token expired, attempting refresh");
          try {
            console.log("Calling handleTokenRefresh...");
            await handleTokenRefresh();
            console.log("Token refresh completed successfully");
          } catch (error) {
            console.error("Token refresh failed:", error);
            // Only clear session if token refresh explicitly fails
            if (
              error.response?.status === 401 ||
              error.response?.status === 403
            ) {
              console.log("Auth error during refresh, clearing session");
              localStorage.removeItem("token");
              localStorage.removeItem("authState");
              localStorage.removeItem("adminViewEnabled");
              setLoggedIn(false);
              setUser(null);
              setProcessInfo(null);
            }
          }
        }
      } else if (document.visibilityState === "hidden") {
        console.log("App minimized - storing current state");
        console.log("Path at minimize:", window.location.pathname);
      }
      console.groupEnd();
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, [handleTokenRefresh]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        const newToken = response.headers["x-new-jwt"];
        if (newToken) {
          console.log("New token received from middleware");
          localStorage.setItem("token", newToken);
          localStorage.setItem(
            "authState",
            JSON.stringify({
              loggedIn: true,
              token: newToken,
              timestamp: Date.now(),
            })
          );
        }
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        console.log("Interceptor error:", {
          status: error.response?.status,
          url: originalRequest.url,
          retryAttempt: originalRequest._retry,
          token: !!localStorage.getItem("token"),
          headers: error.response?.headers,
        });

        // Handle OAuth redirects
        if (originalRequest.url?.includes("oauth2callback")) {
          if (error.response?.status === 302 || error.response?.status === 0) {
            console.log("OAuth redirect detected");
            const redirectUrl =
              error.response?.headers?.location ||
              error.response?.data?.redirectUrl ||
              `/api/auth?skipConsent=false&code=${new URLSearchParams(
                originalRequest.url.split("?")[1]
              ).get("code")}`;

            window.location.href = redirectUrl;
            return Promise.reject(error);
          }
        }

        // Check subscription status from middleware response
        const isSubscriptionError =
          error.response?.status === 403 &&
          (error.response?.data?.subscriptionStatus?.isExpiredTrial ||
            error.response?.headers?.["x-subscription-status"] ===
              "trial-expired");

        if (isSubscriptionError) {
          console.log("Subscription status error detected");
          // Show user-friendly message but don't log them out
          /*    alert(
            "Your trial has expired. Some features will be restricted. Please upgrade to continue using all features."
          ); */
          return Promise.reject(error);
        }

        // Don't retry subscription errors
        if (originalRequest._retry && !isSubscriptionError) {
          localStorage.removeItem("token");
          localStorage.removeItem("authState");
          localStorage.removeItem("adminViewEnabled");
          setLoggedIn(false);
          setUser(null);
          setProcessInfo(null);
          navigate("/");
          return Promise.reject(error);
        }

        // Only retry non-subscription 401/403 errors once
        if (
          (error.response?.status === 401 || error.response?.status === 403) &&
          !isSubscriptionError
        ) {
          originalRequest._retry = true;
          return axios(originalRequest);
        }

        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate]);

  /* 
  * This works, just logs people out on 403s so trying not to do that. 
  
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        const newToken = response.headers["x-new-jwt"];
        if (newToken) {
          console.log("New token received from middleware");
          localStorage.setItem("token", newToken);
          localStorage.setItem(
            "authState",
            JSON.stringify({
              loggedIn: true,
              token: newToken,
              timestamp: Date.now(),
            })
          );
        }
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        console.log("Interceptor error:", {
          status: error.response?.status,
          url: originalRequest.url,
          retryAttempt: originalRequest._retry,
          token: !!localStorage.getItem("token"),
        });

        if (originalRequest._retry) {
          localStorage.removeItem("token");
          localStorage.removeItem("authState");
          localStorage.removeItem("adminViewEnabled");
          setLoggedIn(false);
          setUser(null);
          setProcessInfo(null);
          navigate("/");
          return Promise.reject(error);
        }

        if (error.response?.status === 401 || error.response?.status === 403) {
          originalRequest._retry = true;
          return axios(originalRequest);
        }

        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate]); */

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const success = urlParams.get("success");
    // eslint-disable-next-line no-unused-vars
    const page = urlParams.get("page");
    const googleCode = urlParams.get("code");
    const referralEmail = urlParams.get("referral");
    const inviteToken = urlParams.get("invite");
    const forceConsent = urlParams.get("forceConsent");

    if (referralEmail) {
      localStorage.setItem("referralEmail", referralEmail);
    }

    if (inviteToken) {
      localStorage.setItem("pendingInviteToken", inviteToken);
    }

    const handleGoogleAuth = async () => {
      console.group("Google Auth Detailed Logging");
      console.log("Full Search Params:", location.search);
      console.log("Google Code:", googleCode);
      console.log("Force Consent:", forceConsent);
      console.log("Skip Consent:", urlParams.get("skipConsent"));
      console.groupEnd();

      // If forced consent is required, redirect to /api/auth
      if (forceConsent === "true" || urlParams.get("skipConsent") === "false") {
        console.log("Forcing full authentication flow");
        window.location.href = `/api/auth?skipConsent=false&code=${googleCode}`;
        return;
      }

      try {
        // Now do an XHR (Axios) call instead of a full-page redirect
        console.log("Making Axios request to /api/oauth2callback");
        const response = await axios.get(
          `/api/oauth2callback?code=${googleCode}`
        );

        if (response.data.success) {
          // We got a valid token
          const { token } = response.data;
          localStorage.setItem("token", token);
          console.log(
            "Token stored in localStorage, proceed to fetch session info..."
          );

          // Optionally call fetchSessionInfo or navigate to a logged-in route
          await fetchSessionInfo();
          navigate("/process-info"); // Or wherever you want after successful auth
        } else {
          // Handle missing refresh token or other errors
          if (
            response.data.reason === "missing_refresh_token" &&
            response.data.redirectUrl
          ) {
            console.warn(
              "No refresh token received, redirecting to re-consent"
            );
            window.location.href = response.data.redirectUrl;
          } else {
            console.error(
              "OAuth error:",
              response.data.message || "Unknown issue"
            );
            navigate("/");
          }
        }
      } catch (error) {
        console.error("Error in handleGoogleAuth:", error);
        navigate("/");
      }
    };

    if (googleCode) {
      handleGoogleAuth();
    } else if (success) {
      console.log("Inside app.js handling success=true");
      const token = localStorage.getItem("token");

      if (token) {
        console.log("in token app.js");
        axios
          .get(`/api/get-new-token`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const { token: newToken } = response.data;
            console.log("new token: ", newToken);
            localStorage.setItem("token", newToken);
            localStorage.setItem(
              "authState",
              JSON.stringify({
                loggedIn: true,
                token: newToken,
                timestamp: Date.now(),
              })
            );
            fetchSessionInfo();
            navigate("/process-info");
          })
          .catch((error) => {
            console.error("Error fetching new token:", error);
            navigate("/");
          });
      } else {
        console.error("No token found in localStorage for fetching new token");
        navigate("/");
      }
    } else {
      fetchSessionInfo();
    }
  }, [fetchSessionInfo, location.pathname, location.search, navigate]);

  useEffect(() => {
    if (!loading) {
      // Don't redirect on public routes and success
      if (
        location.pathname === "/success" ||
        location.pathname === "/faq" ||
        location.pathname === "/privacy" ||
        location.pathname === "/quickbooksintegration" ||
        location.pathname === "/quickbooksdisconnect" ||
        location.pathname === "/terms"
      ) {
        return;
      }

      // If we're on process-info and logged in, don't redirect
      if (location.pathname === "/process-info" && loggedIn) {
        return;
      }

      // Handle other cases
      if (!loggedIn && location.pathname !== "/") {
        navigate("/", { replace: true });
      } else if (
        loggedIn &&
        location.pathname === "/" &&
        processInfo?.spreadsheetId
      ) {
        navigate("/process-info", { replace: true });
      }
    }
  }, [loading, loggedIn, location.pathname, navigate, processInfo]);

  useEffect(() => {
    const processReferral = async () => {
      const referralEmail = localStorage.getItem("referralEmail");
      // Clear referral immediately to prevent any possibility of re-processing
      localStorage.removeItem("referralEmail");

      if (referralEmail && loggedIn) {
        try {
          const response = await axios.post(
            "/api/accountant/invite-client",
            {
              clientEmail: referralEmail,
              clientName: "Referred Client",
              fromReferral: true,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.data.success) {
            console.log("Referral email processed successfully");
          } else {
            console.error(
              "Failed to process referral email:",
              response.data.error
            );
          }
        } catch (error) {
          console.error("Error processing referral email:", error);
        }
      }
    };

    if (loggedIn) {
      processReferral();
    }
  }, [loggedIn]);

  //  useEffect to handle Apple auth messages
  useEffect(() => {
    const handleAppleAuth = (event) => {
      if (event.data?.type === "APPLE_AUTH_SUCCESS") {
        console.log("Received Apple auth success:", event.data);
        const { token } = event.data;
        localStorage.setItem("token", token);
        localStorage.setItem("auth_provider", "apple");

        // Call fetchSessionInfo and wait for the user information to be set
        fetchSessionInfo().then(() => {
          setLoggedIn(true);
          const selectedMenu = localStorage.getItem("selectedMenu");
          navigate("/process-info", {
            state: { returnPage: selectedMenu },
            replace: true,
          });
          window.history.replaceState({}, document.title, "/");
        });
      }
    };

    window.addEventListener("message", handleAppleAuth);
    return () => window.removeEventListener("message", handleAppleAuth);
  }, [fetchSessionInfo, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const ClientProcessInfo = () => {
    const { clientEmail } = useParams();
    return <AccountantView clientEmail={clientEmail} />;
  };

  return (
    <QuickBooksProvider>
      <Routes>
        <Route path="/" element={<Home loggedIn={loggedIn} user={user} />} />
        <Route
          path="/signup"
          element={
            loggedIn ? (
              <InviteAcceptance user={user} setProcessInfo={setProcessInfo} />
            ) : (
              <Home loggedIn={loggedIn} user={user} />
            )
          }
        />
        <Route
          path="/invite/:token"
          element={
            loggedIn ? (
              <InviteAcceptance user={user} setProcessInfo={setProcessInfo} />
            ) : (
              <Home loggedIn={loggedIn} user={user} />
            )
          }
        />
        {loggedIn && (
          <>
            <Route
              path="/process-info"
              element={
                !processInfo?.spreadsheetId ? (
                  <FirstTimeUser user={user} setProcessInfo={setProcessInfo} />
                ) : (
                  <ProcessInfo
                    user={user}
                    setUser={setUser}
                    processInfo={processInfo}
                    setProcessInfo={setProcessInfo}
                  />
                )
              }
            />
            <Route
              path="/client/:clientEmail/reports"
              element={<ClientProcessInfo />}
            />
            <Route
              path="/reviewer"
              element={
                <Navigate
                  to={{
                    pathname: "/process-info",
                    search: location.search, // Preserve the ?referrer= query string
                  }}
                  state={{ returnPage: "adminView" }}
                  replace
                />
              }
            />
            {loggedIn && user?.isAdmin && (
              <Route path="/admin" element={<AdminPortal />} />
            )}
            <Route path="/cancel" element={<Navigate to="/" replace />} />
            <Route path="/success" element={<Navigate to="/" replace />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/viewers" element={<Viewers />} />
          </>
        )}
        {/* Keep all your static routes */}
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route
          path="/quickbooksdisconnect"
          element={<QuickbooksDisconnect />}
        />
        <Route
          path="/quickbooksintegration"
          element={<QuickbooksIntegration />}
        />
        <Route path="/terms" element={<TERMSPage />} />
        <Route path="/privacy" element={<PRIVACYPage />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </QuickBooksProvider>
  );
}
export default App;
