import React from "react";
import { Camera, Upload, Car, Search, Cloud } from "lucide-react";
import "../styles/PhotoSelectionContainer.css";

const PhotoSelectionContainer = ({
  handlePhotoSelector,
  processing,
  loading,
  user,
  setMileageModalOpen,
  mileageNotification,
  SHOW_ICLOUD_BUTTON,
}) => {
  return (
    <div className="photo-selection-container">
      <div className="photo-source-buttons">
        {/* Primary Camera Button */}
        <button
          className="primary-button camera-button-featured"
          onClick={() => handlePhotoSelector("camera")}
          disabled={processing || loading.photoSelector}
        >
          <div className="camera-button-content">
            <Camera size={48} strokeWidth={1.8} className="camera-icon" />
            <div className="camera-button-text">
              <span className="primary-text">Take Photo of Receipt</span>
            </div>
          </div>
        </button>

        {/* Secondary Options Container */}
        <div className="secondary-options">
          <button
            className="secondary-button google-button1"
            onClick={() => handlePhotoSelector("google")}
            disabled={processing || loading.photoSelector}
          >
            <Search size={24} />
            Find Receipts in Google Photos
          </button>

          <button
            className="secondary-button upload-button"
            onClick={() => handlePhotoSelector("upload")}
            disabled={processing || loading.photoSelector}
          >
            <Upload size={24} />
            Upload Receipt from Device
          </button>

          {(user?.roles?.type === "I-Pro" ||
            user?.roles?.type === "Employee" ||
            user?.roles?.type === "Employer") && (
            <button
              className="secondary-button mileage-button"
              onClick={() => {
                console.log("Mileage button clicked");
                setMileageModalOpen(true);
              }}
              disabled={processing || loading.photoSelector}
            >
              <Car size={24} />
              Add Mileage Expense
            </button>
          )}

          {SHOW_ICLOUD_BUTTON && (
            <button
              className="secondary-button icloud-button"
              onClick={() => handlePhotoSelector("icloud")}
              disabled={processing || loading.photoSelector}
            >
              <Cloud size={24} />
              Get Photos from iCloud
            </button>
          )}
        </div>

        {/* Notifications */}
        {(loading.photoSelector || mileageNotification) && (
          <div className="notification-box">
            {loading.photoSelector && (
              <div className="notification info">
                <span className="icon">
                  <i className="fas fa-info-circle"></i>
                </span>
                <span>
                  Adding {loading.photoCount > 0 ? loading.photoCount : "your"}{" "}
                  photos to the queue.
                </span>
              </div>
            )}
            {mileageNotification && (
              <div className="notification success">
                <span className="icon">
                  <i className="fas fa-check-circle"></i>
                </span>
                <span>{mileageNotification}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoSelectionContainer;
