// QuickBooksContext.js
import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";

const QuickBooksContext = createContext(null);

export function QuickBooksProvider({ children }) {
  const [isConnected, setIsConnected] = useState(false);
  const isVerifying = useRef(false);
  const verificationTimeout = useRef(null);
  const verifyFn = useRef(null);

  // Store verify function in a ref to keep it stable
  verifyFn.current = async () => {
    if (isVerifying.current) {
      return isConnected;
    }

    try {
      isVerifying.current = true;
      const response = await fetch("/api/quickbooks/verify-connection", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        setIsConnected(false);
        return false;
      }

      const data = await response.json();
      setIsConnected(data.connected);
      return data.connected;
    } catch (err) {
      console.error("Error verifying QuickBooks connection:", err);
      setIsConnected(false);
      return false;
    } finally {
      isVerifying.current = false;
    }
  };

  // Expose a stable version of verify
  const verifyConnection = async () => {
    return verifyFn.current();
  };

  useEffect(() => {
    // Initial verification
    verifyFn.current();

    // Set up interval for periodic checks
    const intervalId = setInterval(() => {
      // Clear any pending verification
      if (verificationTimeout.current) {
        clearTimeout(verificationTimeout.current);
      }

      // Set new verification with slight delay to prevent bunching
      verificationTimeout.current = setTimeout(() => {
        verifyFn.current();
      }, 1000);
    }, 5 * 60 * 1000); // Check every 5 minutes

    // Cleanup
    return () => {
      clearInterval(intervalId);
      if (verificationTimeout.current) {
        clearTimeout(verificationTimeout.current);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const value = {
    isConnected,
    setIsConnected,
    verifyConnection,
  };

  return (
    <QuickBooksContext.Provider value={value}>
      {children}
    </QuickBooksContext.Provider>
  );
}

export function useQuickBooks() {
  const context = useContext(QuickBooksContext);
  if (!context) {
    throw new Error("useQuickBooks must be used within a QuickBooksProvider");
  }
  return context;
}
