import React, { useState, useRef, useEffect, useMemo } from "react";
import { uploadToGooglePhotos } from "./cameraPhotosPicker";
import "../styles/EnhancedCameraSelector.css";

const EnhancedCameraSelector = ({ onClose, onPhotosSelected, onError }) => {
  const [status, setStatus] = useState("initializing");
  const [capturedImage, setCapturedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const fileInputRef = useRef(null);
  const canvasRef = useRef(document.createElement("canvas"));

  const getDeviceSupport = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const iOSVersion = isIOS
      ? parseInt(navigator.userAgent.match(/ OS (\d+)_/)?.[1] || "0", 10)
      : 0;
    const hasGetUserMedia = !!navigator.mediaDevices?.getUserMedia;

    return {
      useCamera: hasGetUserMedia && (!isIOS || iOSVersion >= 14),
      useCapture: isIOS,
    };
  };

  const deviceSupport = useMemo(() => getDeviceSupport(), []);

  const setupCamera = async () => {
    const resolutionSteps = [
      { width: 3840, height: 2160 }, // 4K
      { width: 2560, height: 1440 }, // QHD
      { width: 1920, height: 1080 }, // FHD
      { width: 1280, height: 720 }, // HD
    ];

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    for (const resolution of resolutionSteps) {
      try {
        const constraints = {
          video: {
            facingMode: "environment",
            width: { ideal: resolution.width, min: 1280 },
            height: { ideal: resolution.height, min: 720 },
            frameRate: { ideal: 30 },
            // Add Safari-specific settings if needed
            ...(isSafari && {
              autoFocusMode: "continuous",
            }),
          },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        return stream;
      } catch (error) {
        if (resolution.width === 1280) throw error;
        continue;
      }
    }
  };

  // Single useEffect for all device logging
  useEffect(() => {
    const logDeviceInfo = async () => {
      // Basic device info - log once
      console.log("Device Memory:", navigator.deviceMemory);
      console.log("Hardware Concurrency:", navigator.hardwareConcurrency);
      console.log("User Agent:", navigator.userAgent);
    };
    logDeviceInfo();
  }, []); // Empty dependency array - runs once

  // Separate effect for video capabilities
  useEffect(() => {
    if (status === "ready" && streamRef.current) {
      // Only log when camera is ready
      const videoTrack = streamRef.current.getVideoTracks()[0];
      if (videoTrack) {
        const capabilities = videoTrack.getCapabilities();
        console.log("Video Capabilities:", capabilities);

        // Use capabilities to optimize camera settings if available
        if (capabilities) {
          try {
            videoTrack.applyConstraints({
              advanced: [
                {
                  // Only apply supported capabilities
                  ...(capabilities.sharpness && {
                    sharpness: capabilities.sharpness.max,
                  }),
                  ...(capabilities.brightness && {
                    brightness: capabilities.brightness.max,
                  }),
                  ...(capabilities.contrast && {
                    contrast: capabilities.contrast.max,
                  }),
                },
              ],
            });
          } catch (e) {
            console.log("Could not apply advanced camera settings");
          }
        }
      }
    }
  }, [status]); // Only depends on status

  useEffect(() => {
    const initCamera = async () => {
      if (!deviceSupport.useCamera) {
        setStatus("fallback");
        return;
      }

      try {
        const stream = await setupCamera();
        streamRef.current = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => setStatus("ready");
        }
      } catch (error) {
        console.error("Camera initialization error:", error);
        setStatus("fallback");
      }
    };

    initCamera();
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, [deviceSupport.useCamera]);

  const processImage = (canvas, ctx) => {
    if (!canvas.width || !canvas.height) return;

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    // Calculate brightness for adaptive processing
    let totalBrightness = 0;
    for (let i = 0; i < data.length; i += 4) {
      totalBrightness +=
        0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2];
    }
    const avgBrightness = totalBrightness / (data.length / 4);
    const isLowLight = avgBrightness < 128;

    // Process each pixel while preserving color
    for (let i = 0; i < data.length; i += 4) {
      for (let j = 0; j < 3; j++) {
        let value = data[i + j];

        // Apply adaptive contrast
        const contrast = isLowLight ? 22 : 15; // Increased for better text
        const factor = (259 * (contrast + 255)) / (255 * (259 - contrast));
        value = Math.round(factor * (value - 128) + 128);

        // Apply brightness boost
        const brightness = isLowLight ? 12 : 6;
        value += brightness;

        // Enhance text edges
        if (
          i % (canvas.width * 4) > 4 &&
          i % (canvas.width * 4) < canvas.width * 4 - 4
        ) {
          const leftPixel = data[i - 4 + j];
          const rightPixel = data[i + 4 + j];
          const edgeDifference = Math.abs(leftPixel - rightPixel);

          if (edgeDifference > 25) {
            // Reduced threshold to catch more text edges
            if (value > 128) {
              value = Math.min(255, value + 15); // Increased edge enhancement
            } else {
              value = Math.max(0, value - 15);
            }
          }
        }

        data[i + j] = Math.min(255, Math.max(0, value));
      }
    }

    // Sharpen more aggressively for text
    const width = canvas.width;
    const tempData = new Uint8ClampedArray(data);

    for (let y = 1; y < canvas.height - 1; y++) {
      for (let x = 1; x < width - 1; x++) {
        for (let c = 0; c < 3; c++) {
          const idx = (y * width + x) * 4 + c;
          const current = tempData[idx];

          const up = tempData[idx - width * 4];
          const down = tempData[idx + width * 4];
          const left = tempData[idx - 4];
          const right = tempData[idx + 4];

          const sharpened = current * 1.7 - (up + down + left + right) / 6; // Increased from 1.5
          data[idx] = Math.min(255, Math.max(0, sharpened));
        }
      }
    }

    ctx.putImageData(imageData, 0, 0);
  };

  const handleCapture = async () => {
    try {
      // Don't set isProcessing yet, so no spinner initially
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d", {
        alpha: false,
        willReadFrequently: true,
      });
      ctx.imageSmoothingEnabled = false;
      ctx.webkitImageSmoothingEnabled = false;

      // Freeze frame dimensions
      const width = videoRef.current.videoWidth;
      const height = videoRef.current.videoHeight;
      canvas.width = width;
      canvas.height = height;

      // 1) Draw immediately to capture the freeze-frame
      ctx.drawImage(videoRef.current, 0, 0);

      // 2) Show the freeze-frame right away
      setCapturedImage(canvas.toDataURL("image/jpeg", 0.9));
      setStatus("preview");

      // 3) Optionally show spinner *after* freeze-frame is displayed
      //    Only if you have a heavy process or want to indicate "enhancing..."
      // setIsProcessing(true);

      // Slight delay so the preview updates visually before we do any heavier work
      await new Promise((resolve) => setTimeout(resolve, 50));

      // 4) Background image processing
      processImage(canvas, ctx);

      // 5) Update the captured image with the processed version
      setCapturedImage(canvas.toDataURL("image/jpeg", 0.9));
    } catch (error) {
      console.error("Capture error:", error);
      onError?.(`Failed to capture: ${error.message}`);
    } finally {
      // 6) Hide spinner if you turned it on above
      setIsProcessing(false);
    }
  };

  const handleRetake = async () => {
    setCapturedImage(null);
    setStatus("initializing");

    try {
      const stream = await setupCamera();
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => setStatus("ready");
      }
    } catch (error) {
      console.error("Camera reinitialization error:", error);
      onError?.(error.message);
      setStatus("fallback");
    }
  };

  const handleAcceptImage = async () => {
    try {
      setIsProcessing(true);

      // Create a new canvas for the upload
      const canvas = canvasRef.current;
      const img = new Image();

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = capturedImage;
      });

      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d", { alpha: false });
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(img, 0, 0);

      // Create blob directly from canvas
      const blob = await new Promise((resolve, reject) =>
        canvas.toBlob(resolve, "image/jpeg", 9)
      );

      if (!blob) {
        throw new Error("Failed to create image blob");
      }

      console.log(
        "Upload blob size:",
        Math.round((blob.size / 1024 / 1024) * 100) / 100,
        "MB"
      );

      const mediaItem = await uploadToGooglePhotos(blob);

      onPhotosSelected([
        {
          id: mediaItem.id,
          baseUrl: mediaItem.baseUrl,
          mimeType: "image/jpeg",
          filename: `Receipt-${Date.now()}.jpg`,
          mediaMetadata: {
            creationTime: new Date().toISOString(),
          },
        },
      ]);

      onClose();
    } catch (error) {
      console.error("Upload error details:", error);
      onError?.("Failed to upload. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsProcessing(true);
      setStatus("processing");

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const img = await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
      });

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);
      processImage(canvas, ctx);

      const dataUrl = canvas.toDataURL("image/jpeg", 0.9);
      setCapturedImage(dataUrl);
      setStatus("preview");
    } catch (error) {
      console.error("File processing error:", error);
      onError?.(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content enhanced-camera">
        <button onClick={onClose} className="close-xc">
          ×
        </button>

        {status === "fallback" ? (
          <div className="file-input-container">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              {...(deviceSupport.useCapture ? { capture: "environment" } : {})}
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
            <button
              className="capture-button"
              onClick={() => fileInputRef.current?.click()}
            >
              <i className="fas fa-camera"></i>
            </button>
          </div>
        ) : (
          <div className="enhanced-camera-container">
            {capturedImage ? (
              <>
                <img
                  src={capturedImage}
                  alt="Captured"
                  className="enhanced-camera-preview" // Updated
                />
                <div className="preview-controls">
                  <button
                    onClick={handleRetake}
                    className="preview-button1 retake"
                  >
                    Retake
                  </button>
                  <button
                    onClick={handleAcceptImage}
                    className="preview-button1"
                  >
                    Accept
                  </button>
                </div>
              </>
            ) : (
              <>
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  webkit-playsinline="true"
                  className="enhanced-camera-preview"
                  style={{ backgroundColor: "transparent" }}
                />
              </>
            )}

            {status === "ready" && !capturedImage && (
              <button
                onClick={handleCapture}
                className="capture-button"
                disabled={isProcessing} // Remove isFocusing check
              >
                <i className="fas fa-camera"></i>
              </button>
            )}
          </div>
        )}

        {isProcessing && (
          <div className="processing-status">
            <i className="fas fa-spinner fa-spin"></i>
            <span>Processing image...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnhancedCameraSelector;
