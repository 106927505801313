import React, { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { jwtDecode } from "jwt-decode";
import "../styles/AdminPortal.css";

const AdminPortal = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [editingLicenseCount, setEditingLicenseCount] = useState(0);
  const [showAssignedUsers, setShowAssignedUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({
    email: "",
    licenseCount: "",
    expiryDate: new Date(),
    companyName: "",
  });

  useEffect(() => {
    fetchEnterpriseUsers();
  }, []);

  const fetchEnterpriseUsers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      console.log("About to make admin request with token:", jwtDecode(token));

      const response = await axios.get("/api/admin/enterprise-users", {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-request-id": Date.now(),
        },
      });
      console.log("Enterprise users response:", response.data);
      setUsers(response.data.users);
    } catch (err) {
      console.error("Error details:", err.response?.data);
      setError(
        err.response?.data?.message || "Failed to fetch enterprise users"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateLicenseCount = async (email) => {
    try {
      await axios.post(
        "/api/admin/update-license-count",
        {
          email,
          licenseCount: editingLicenseCount,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setIsEditing(null);
      await fetchEnterpriseUsers();
    } catch (err) {
      setError("Failed to update license count");
    }
  };

  const handleRevertUser = async (email) => {
    if (
      !window.confirm(
        `Are you sure you want to remove enterprise access for ${email}? \n\nThis will:\n` +
          `- Remove all their assigned licenses\n` +
          `- Convert them back to a regular user\n` +
          `- Cannot be undone without re-adding them`
      )
    )
      return;

    try {
      await axios.post(
        "/api/admin/revert-enterprise",
        { email },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      await fetchEnterpriseUsers();
      setError("");
    } catch (err) {
      setError("Failed to revert enterprise user");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      await axios.post(
        "/api/admin/set-enterprise",
        {
          ...newUser,
          expiryDate: newUser.expiryDate.toISOString(),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setNewUser({
        email: "",
        licenseCount: "",
        expiryDate: new Date(),
        companyName: "",
      });
      await fetchEnterpriseUsers();
    } catch (err) {
      setError(err.response?.data?.message || "Failed to add enterprise user");
    } finally {
      setLoading(false);
    }
  };

  const fetchAssignedUsers = async (employerEmail) => {
    try {
      const response = await axios.get(
        `/api/admin/assigned-users/${employerEmail}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response.data.assignedUsers;
    } catch (err) {
      setError("Failed to fetch assigned users");
      return [];
    }
  };

  // Update handleViewAssignedLicenses:
  const handleViewAssignedLicenses = async (email) => {
    const user = users.find((u) => u.email === email);
    const assignedUsers = await fetchAssignedUsers(email);
    setSelectedUser({
      ...user,
      assignedUsers: assignedUsers,
    });
    setShowAssignedUsers(true);
  };

  const updateExpiryDate = async (email, newDate) => {
    try {
      await axios.post(
        "/api/admin/set-enterprise",
        {
          email,
          expiryDate: newDate.toISOString(),
          licenseCount: users.find((u) => u.email === email)?.licenseCount,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      await fetchEnterpriseUsers();
    } catch (err) {
      setError("Failed to update expiry date");
    }
  };

  return (
    <div className="admin-view-container">
      <h2>Enterprise License Management</h2>

      {/* Add New Enterprise User */}
      <div className="enterprise-form-section">
        <h3>Add Enterprise User</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label">
              Email Address
              <div className="info-tooltip1">
                The enterprise user's email address
              </div>
            </label>
            <input
              type="email"
              placeholder="Enter email address"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">
              Company Name
              <div className="info-tooltip1">Name of the organization</div>
            </label>
            <input
              type="text"
              placeholder="Enter company name"
              value={newUser.companyName}
              onChange={(e) =>
                setNewUser({ ...newUser, companyName: e.target.value })
              }
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">
              Number of Licenses
              <div className="info-tooltip1">
                How many user licenses to allocate to this organization
              </div>
            </label>
            <input
              type="number"
              placeholder="Enter number of licenses"
              min="1"
              value={newUser.licenseCount}
              onChange={(e) =>
                setNewUser({ ...newUser, licenseCount: e.target.value })
              }
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">
              Subscription End Date
              <div className="info-tooltip1">
                When their enterprise license subscription expires
              </div>
            </label>
            <DatePicker
              selected={newUser.expiryDate}
              onChange={(date) => setNewUser({ ...newUser, expiryDate: date })}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select subscription end date"
            />
          </div>

          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? (
              <TailSpin color="#ffffff" height={16} width={16} />
            ) : (
              "Add Enterprise User"
            )}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>

      {/* Enterprise Users List */}
      <div className="enterprise-users-section">
        <h3>Current Enterprise Users</h3>
        <div className="table-responsive">
          <table className="enterprise-table">
            <thead>
              <tr>
                <th>Company</th>
                <th>Email</th>
                <th>Licenses (Used/Total)</th>
                <th>Subscription Ends</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.email}>
                  <td>{user.companyName}</td>
                  <td>{user.email}</td>
                  <td>
                    {isEditing === user.email ? (
                      <div className="license-edit">
                        <input
                          type="number"
                          value={editingLicenseCount}
                          onChange={(e) =>
                            setEditingLicenseCount(e.target.value)
                          }
                          min="1"
                          className="license-edit-input"
                        />
                        <button
                          onClick={() => handleUpdateLicenseCount(user.email)}
                          className="save-button"
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      <span
                        className="editable-field"
                        onClick={() => {
                          setIsEditing(user.email);
                          setEditingLicenseCount(user.licenseCount);
                        }}
                      >
                        {user.assignedLicenses}/{user.licenseCount}
                        <i className="fas fa-edit edit-icon"></i>
                      </span>
                    )}
                  </td>
                  <td>
                    <DatePicker
                      selected={new Date(user.expiryDate)}
                      onChange={(date) => updateExpiryDate(user.email, date)}
                      dateFormat="yyyy-MM-dd"
                      className="date-picker"
                    />
                  </td>
                  <td>
                    <div className="action-buttons1">
                      <button
                        onClick={() => handleViewAssignedLicenses(user.email)}
                        className="action-button1"
                      >
                        View Assigned Users ({user.assignedLicenses})
                      </button>
                      <button
                        onClick={() => handleRevertUser(user.email)}
                        className="danger-button"
                      >
                        Remove Enterprise Access
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for viewing assigned users */}
      {showAssignedUsers && selectedUser && (
        <div className="modal">
          <div className="modal-content">
            <h3>Assigned Users for {selectedUser.companyName}</h3>
            <div className="assigned-users-list">
              {selectedUser.assignedUsers?.length > 0 ? (
                selectedUser.assignedUsers.map((user) => (
                  <div key={user.email} className="assigned-user-item">
                    <span>{user.email}</span>
                    <span>
                      {new Date(user.assignedAt).toLocaleDateString()}
                    </span>
                  </div>
                ))
              ) : (
                <p>No users assigned yet</p>
              )}
            </div>
            <button
              onClick={() => setShowAssignedUsers(false)}
              className="action-button1"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPortal;
