import { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import "../styles/shareToQuickBooks.css";
import { createPortal } from "react-dom";
import { useQuickBooks } from "./QuickbooksContext";

const ValidationError = ({ issues }) => {
  return (
    <div className="mt-4 bg-red-50 border border-red-100 rounded-lg p-4">
      <h3 className="font-medium text-red-800 mb-2">Validation Issues</h3>
      {issues.map((issue, index) => (
        <div key={index} className="mb-2 last:mb-0">
          <p className="text-red-700">{issue.message}</p>
          <p className="text-sm text-red-600 mt-1">
            Row {issue.row} - {issue.field}
          </p>
        </div>
      ))}
    </div>
  );
};

const ShareToQuickBooks = ({
  reportId,
  spreadsheetId,
  quickbooks,
  isConnected: initialConnectionStatus,
  onStatusUpdate,
  onConnectionStatusChange,
  isClientOfAccountant,
  clientEmail,
}) => {
  const { isConnected, verifyConnection, setIsConnected } = useQuickBooks();

  const [isSharing, setIsSharing] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false); // Add this new state

  const [error, setError] = useState(null);
  const [mappingResults, setMappingResults] = useState(null);
  const [validationIssues, setValidationIssues] = useState(null);
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);

  useEffect(() => {
    setIsSharing(false);
  }, []); // Reset isSharing on mount

  // Verify connection on mount
  useEffect(() => {
    verifyConnection();
  }, [verifyConnection]);

  const handleConnect = async () => {
    setIsConnecting(true);
    try {
      const currentPath = window.location.pathname + window.location.search;

      if (isClientOfAccountant && clientEmail) {
        localStorage.setItem(
          "qbContext",
          JSON.stringify({
            isAccountantView: true,
            clientEmail: clientEmail,
            returnPath: currentPath,
          })
        );
      } else {
        localStorage.setItem(
          "qbContext",
          JSON.stringify({
            isAccountantView: false,
            returnPath: currentPath,
          })
        );
      }

      localStorage.setItem("selectedMenu", "expenseReports");

      const url = isClientOfAccountant
        ? `/api/quickbooks/connect?clientEmail=${encodeURIComponent(
            clientEmail
          )}&returnPath=${encodeURIComponent(currentPath)}`
        : `/api/quickbooks/connect?returnPath=${encodeURIComponent(
            currentPath
          )}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to initiate connection");
      }

      const data = await response.json();
      if (data.authUri) {
        window.location.href = data.authUri;
      } else {
        throw new Error("No authorization URI received");
      }
    } catch (err) {
      console.error("Connection error:", err);
      setError("Failed to initiate QuickBooks connection");
      onConnectionStatusChange?.(false); // Changed from handleConnectionChange
    } finally {
      setIsConnecting(false);
    }
  };

  const handleDisconnect = async () => {
    if (isDisconnecting) return;

    setIsDisconnecting(true);
    try {
      const response = await fetch("/api/quickbooks/disconnect", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to disconnect: ${response.status}`);
      }

      // Update both context and parent
      setIsConnected(false);
      onConnectionStatusChange?.(false);
    } catch (err) {
      console.error("Disconnect error:", err);
      setError("Failed to disconnect from QuickBooks");
      // Still update states on error
      setIsConnected(false);
      onConnectionStatusChange?.(false);
    } finally {
      setIsDisconnecting(false);
      setShowDisconnectConfirm(false);
    }
  };

  const handleShare = async () => {
    setIsSharing(true);
    setError(null);
    setMappingResults(null);
    setValidationIssues(null);

    try {
      // Verify connection before proceeding
      const isStillConnected = await verifyConnection();
      if (!isStillConnected) {
        throw new Error(
          "QuickBooks connection lost. Please reconnect to continue."
        );
      }

      // Proceed with share operation
      const shareResponse = await fetch("/api/quickbooks/share-report", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          reportId,
          clientEmail: isClientOfAccountant ? clientEmail : undefined,
        }),
      });

      const shareData = await shareResponse.json();

      // Handle reauthorization cases
      if (shareResponse.status === 401 && shareData.reauthorizationUrl) {
        window.location.href = shareData.reauthorizationUrl;
        return;
      }

      // Handle successful response
      if (shareResponse.ok) {
        onStatusUpdate?.({
          status: "submitted",
          submittedAt: new Date().toISOString(),
          billId: shareData.billId,
          amount: shareData.amount,
          date: shareData.date,
          functionalTotal: shareData.functionalTotal,
        });
        return;
      }

      // Rest of your error handling stays the same...
      if (shareData.errorCode === "QUICKBOOKS_USERNAME_MISSING") {
        const sheetUrl = constructSheetUrl(
          spreadsheetId,
          shareData.details.gid
        );
        alert(`${shareData.message}\nClick OK to open the settings sheet.`);
        window.open(sheetUrl, "_blank");
        throw new Error(
          "Please add your QuickBooks Vendor Display Name and try again"
        );
      }

      // Handle vendor not found errors
      if (shareData.errorCode === "QUICKBOOKS_VENDOR_NOT_FOUND") {
        const sheetUrl = constructSheetUrl(
          spreadsheetId,
          shareData.details.gid
        );
        alert(`${shareData.message}\nClick OK to open the settings sheet.`);
        window.open(sheetUrl, "_blank");
        throw new Error(
          "Please correct your QuickBooks Vendor Display Name and try again"
        );
      }

      // Handle validation issues
      if (shareData.issues) {
        const sheetUrl = constructSheetUrl(reportId);
        alert(`${shareData.message}\nClick OK to open the spreadsheet.`);
        window.open(sheetUrl, "_blank");
        setError(
          "Validation failed. Please check your Func. Total values and try again."
        );
        setValidationIssues(shareData.issues);
        throw new Error("Validation failed");
      }

      // Handle other errors
      if (
        shareData.error?.includes("reconnect") ||
        shareData.error?.includes("expired")
      ) {
        onConnectionStatusChange?.(false); // Changed from setIsConnected(false)
        throw new Error(
          "QuickBooks connection expired. Please reconnect to continue."
        );
      }

      throw new Error(shareData.error || "Failed to share report");
    } catch (error) {
      setError(error.message);
      if (
        error.message?.includes("reconnect") ||
        error.message?.includes("expired")
      ) {
        setIsConnected(false);
        onConnectionStatusChange?.(false);
      }
      onStatusUpdate?.({
        status: "failed",
        error: error.message,
        submittedAt: new Date().toISOString(),
      });
    } finally {
      setIsSharing(false);
    }
  };

  // Helper function for constructing spreadsheet URLs
  const constructSheetUrl = (sheetId, gid) => {
    return gid
      ? `https://docs.google.com/spreadsheets/d/${sheetId}/edit#gid=${gid}`
      : `https://docs.google.com/spreadsheets/d/${sheetId}/edit`;
  };

  // If already submitted, show submitted status
  if (quickbooks?.status === "submitted") {
    return (
      <div className="submitted-status flex items-center space-x-2">
        <i className="fas fa-check-circle text-green-500 text-xl"></i>
        <span className="text-green-500 font-medium">
          Submitted to QuickBooks
        </span>
      </div>
    );
  }

  // If not connected, show the official Connect to QuickBooks button
  // If not connected, show the official Connect to QuickBooks button
  if (!isConnected) {
    return (
      <div className="space-y-4">
        <button
          onClick={handleConnect}
          disabled={isConnecting}
          className="connect-to-quickbooks-button"
          style={{
            background: "none",
            border: "none",
            padding: 0,
            cursor: isConnecting ? "default" : "pointer",
            width: "208px", // Per QuickBooks guidelines - maintain 156x26 aspect ratio
            height: "35px",
            display: "inline-block",
          }}
        >
          {isConnecting ? (
            <div className="flex items-center justify-center">
              <TailSpin color="#4B5563" height={20} width={20} />
              <span className="ml-2">Connecting...</span>
            </div>
          ) : (
            <img
              src="/C2QB_green.png" // Update this path to where you store the SVG
              alt="Connect to QuickBooks"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </button>
        {error && <p className="text-red-500">{error}</p>}
      </div>
    );
  }

  // Connected state - show share button and disconnect option
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <button
          onClick={handleShare}
          disabled={isSharing}
          className={`share-to-qb-btn share-state ${
            isSharing ? "disabled" : ""
          }`}
        >
          <div className="flex items-center justify-center space-x-2">
            {isSharing ? (
              <>
                <i className="fas fa-circle-notch fa-spin"></i>
                <span>Processing...</span>
              </>
            ) : (
              <>
                <i className="fab fa-quickbooks"></i>
                <span>Share to QuickBooks</span>
              </>
            )}
          </div>
        </button>
        <button
          onClick={() => setShowDisconnectConfirm(true)}
          className="disconnect-btn"
        >
          Disconnect
        </button>
      </div>

      {/* Disconnect confirmation modal */}
      {showDisconnectConfirm &&
        createPortal(
          <div
            className="fixed inset-0 z-[9999]"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {/* Solid backdrop to prevent background color blending */}
            <div
              className="absolute inset-0 bg-[#00000080]" // Using hex with alpha instead of opacity
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
              onClick={() =>
                !isDisconnecting && setShowDisconnectConfirm(false)
              }
            />

            {/* Modal content with solid white background */}
            <div
              className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md bg-white rounded-lg shadow-xl p-6"
              style={{
                position: "fixed",
                zIndex: 10000,
                backgroundColor: "#FFFFFF",
              }}
            >
              <h3 className="text-lg font-semibold text-gray-900">
                Confirm Disconnect
              </h3>
              <p className="mt-2 text-sm text-gray-600">
                Are you sure you want to disconnect from QuickBooks?
              </p>
              <div className="mt-4 flex justify-end gap-3">
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isDisconnecting) {
                      setShowDisconnectConfirm(false);
                    }
                  }}
                  disabled={isDisconnecting}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm disabled:opacity-50"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDisconnect();
                  }}
                  disabled={isDisconnecting}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md shadow-sm disabled:opacity-50"
                >
                  {isDisconnecting ? "Disconnecting..." : "Disconnect"}
                </button>
              </div>
            </div>
          </div>,
          document.body
        )}

      {validationIssues && <ValidationError issues={validationIssues} />}
      {mappingResults && (
        <div className="mt-4">
          {/* ... rest of your mapping results code ... */}
        </div>
      )}
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default ShareToQuickBooks;
