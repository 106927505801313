import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import ShareToQuickBooks from "./ShareToQuickBooks";
import ShareToWave from "./ShareToWave";
import "../styles/ProcessInfo.css";

const AccountantView = ({ clientEmail }) => {
  const [accountantData, setAccountantData] = useState(null);
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  const [isLoadingAccountantView, setIsLoadingAccountantView] = useState(false);
  const [quickbooksConnectionStatus, setQuickbooksConnectionStatus] =
    useState(false);
  const [reportFilter, setReportFilter] = useState("All");
  const [editingReportId, setEditingReportId] = useState(null);
  const [editedNote, setEditedNote] = useState("");
  const navigate = useNavigate();

  const [waveConnectionStatus, setWaveConnectionStatus] = useState(false);
  const SHOW_WAVE_BUTTON = false; // Set to `true` if you want it visible

  // Escape key handler remains the same

  useEffect(() => {
    const checkWaveConnection = async () => {
      if (reports.length > 0) {
        try {
          const waveResponse = await fetch("/api/wave/status", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (waveResponse.ok) {
            const status = await waveResponse.json();
            setWaveConnectionStatus(status?.connected || false);
          }
        } catch (error) {
          console.error("Error checking Wave connection:", error);
          setWaveConnectionStatus(false);
        }
      }
    };

    checkWaveConnection();
  }, [reports]);

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") {
        setEditingReportId(null);
      }
    };

    if (editingReportId) {
      window.addEventListener("keydown", handleEsc);
      return () => window.removeEventListener("keydown", handleEsc);
    }
  }, [editingReportId]);

  // Updated data fetching for new structure
  useEffect(() => {
    let isMounted = true;
    const fetchAccountantData = async () => {
      if (!clientEmail) {
        console.log("No client email provided");
        return;
      }

      try {
        setIsLoadingAccountantView(true);

        console.log("Fetching data for client:", clientEmail);
        const response = await axios.get(
          `/api/client-reports/${clientEmail}?page=${currentPage}&limit=10&filter=${reportFilter}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        console.log("Response received:", response.data);

        if (!isMounted) return;

        if (response.data.success) {
          // Set basic client info
          setAccountantData(response.data.processInfo);

          // Set reports from the response
          console.log("Setting reports:", response.data.reports);
          setReports(response.data.reports || []);

          // Set pagination data if available
          if (response.data.pagination) {
            setTotalPages(response.data.pagination.totalPages);
          }

          // Check QuickBooks connection if there are reports
          if (response.data.reports?.length > 0) {
            const qbResponse = await fetch("/api/quickbooks/status", {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });

            if (qbResponse.ok && isMounted) {
              const status = await qbResponse.json();
              setQuickbooksConnectionStatus(status?.connected || false);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching accountant data:", error);
      } finally {
        if (isMounted) {
          setIsLoadingAccountantView(false);
        }
      }
    };

    fetchAccountantData();

    return () => {
      isMounted = false;
    };
  }, [clientEmail, currentPage, reportFilter]); // Add dependencies for pagination and filtering

  // Updated status update handler
  const handleStatusUpdate = async (reportId, newStatus) => {
    try {
      console.log("Attempting update with:", {
        clientEmail,
        reportId,
        newStatus,
      });

      const response = await axios.post(
        "/api/accountant/update-client-report",
        {
          reportId,
          status: newStatus,
          clientEmail: clientEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "Report updated successfully") {
        setReports(
          reports.map((report) =>
            report.id === reportId ? { ...report, status: newStatus } : report
          )
        );
      }
    } catch (error) {
      console.error("Failed to update status:", error);
      alert("Failed to update status. Please try again.");
    }
  };

  const handleNoteUpdate = async (reportId, newNote) => {
    try {
      const response = await axios.post(
        "/api/accountant/update-client-report",
        {
          reportId,
          notes: newNote,
          clientEmail: clientEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "Report updated successfully") {
        setReports(
          reports.map((report) =>
            report.id === reportId ? { ...report, notes: newNote } : report
          )
        );
        setEditingReportId(null);
      }
    } catch (error) {
      console.error("Failed to save note:", error);
      alert("Failed to save note. Please try again.");
    }
  };
  const formatCurrency = (amount, currency) => {
    if (!currency) return amount.toLocaleString();

    const currencyMap = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      JPY: "¥",
      AUD: "A$",
      CAD: "C$",
      CHF: "Fr",
      CNY: "¥",
    };

    const symbol = currencyMap[currency] || currency;
    const formattedAmount = amount.toLocaleString();
    return currencyMap[currency]
      ? `${symbol}${formattedAmount}`
      : `${formattedAmount} ${symbol}`;
  };

  const renderNotesModal = () => {
    if (!editingReportId) return null;

    return (
      <div
        className="eb-modal-overlay"
        onClick={() => setEditingReportId(null)}
      >
        <div className="eb-notes-modal" onClick={(e) => e.stopPropagation()}>
          <div className="eb-notes-content">
            <h4>Report Notes</h4>
            <textarea
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
              placeholder="Add notes about this report..."
              className="eb-notes-textarea"
              autoFocus
            />
            <div className="eb-modal-actions">
              <button
                className="eb-modal-save"
                onClick={() => handleNoteUpdate(editingReportId, editedNote)}
              >
                Save
              </button>
              <button
                className="eb-modal-cancel"
                onClick={() => setEditingReportId(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReports = () => (
    <div className="expense-reports-container">
      <div className="reports-header">
        <h3 className="expense-report-title">Submitted Reports</h3>
        <div className="filter-selector">
          <select
            value={reportFilter}
            onChange={(e) => {
              setReportFilter(e.target.value);
              setCurrentPage(1); // Reset to first page when changing filter
            }}
            className="status-select"
          >
            <option value="All">All Reports</option>
            <option value="Paid">Paid</option>
            <option value="Unpaid">Unpaid</option>
          </select>
        </div>
      </div>
      {reports.length > 0 ? (
        <>
          <div className="report-grid">
            {reports.map((report, index) => {
              if (!report.submissionDate || !report.link) return null;

              return (
                <div key={report.id || index} className="report-row">
                  {/* Primary row with date, title, status and actions */}
                  <div className="report-primary-row">
                    <div className="report-date">
                      {new Date(report.submissionDate).toLocaleDateString()}
                    </div>
                    <div className="report-title">
                      <a
                        href={report.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {report.title || "Untitled Report"}
                      </a>
                    </div>
                    <div className="report-controls">
                      <select
                        value={report.status || "Submitted"}
                        onChange={(e) =>
                          handleStatusUpdate(report.id, e.target.value)
                        }
                        className="status-select"
                        title={report.status || "Submitted"}
                      >
                        <option value="Submitted">Submitted</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Paid">Paid</option>
                      </select>

                      <div className="action-buttons">
                        <button
                          className="action-button"
                          onClick={() => {
                            if (editingReportId === report.id) {
                              setEditingReportId(null);
                            } else {
                              setEditingReportId(report.id);
                              setEditedNote(report.notes || "");
                            }
                          }}
                          title={report.notes ? "Edit note" : "Add note"}
                        >
                          <i className="fas fa-sticky-note"></i>
                        </button>

                        {report.sentTo?.length > 0 && (
                          <div className="action-wrapper">
                            <button className="action-button">
                              <i className="fas fa-envelope"></i>
                              <span className="recipient-count">
                                {report.sentTo.length}
                              </span>
                            </button>
                            <div className="tooltip-content">
                              <div className="tooltip-header">Recipients:</div>
                              {report.sentTo.map((email, i) => (
                                <div key={i} className="recipient">
                                  {email}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Metrics row */}
                  <div className="report-metrics">
                    <div className="metric">
                      <span className="metric-label">Receipts:</span>
                      <span className="metric-value">
                        {report.receiptCount || 0}
                      </span>
                    </div>

                    {report.functionalTotal > 0 && (
                      <div className="metric">
                        <span className="metric-label">Func. Total:</span>
                        <span className="metric-value">
                          {report.homeCurrency
                            ? formatCurrency(
                                report.functionalTotal || 0,
                                report.homeCurrency
                              )
                            : (report.functionalTotal || 0).toLocaleString()}
                        </span>
                      </div>
                    )}
                  </div>

                  {/* QuickBooks section */}
                  {/* QuickBooks section - Update styling */}
                  <div className="quickbooks-section">
                    <div className="integration-container">
                      <ShareToQuickBooks
                        reportId={report.id}
                        spreadsheetId={accountantData.spreadsheetId}
                        quickbooks={report.quickbooks}
                        isConnected={quickbooksConnectionStatus}
                        onStatusUpdate={(newStatus) => {
                          console.log(
                            "Updating report with new status:",
                            newStatus
                          );
                          setReports((prevReports) =>
                            prevReports.map((r) =>
                              r.id === report.id
                                ? {
                                    ...r,
                                    quickbooks: {
                                      ...r.quickbooks,
                                      ...newStatus,
                                    },
                                    functionalTotal: newStatus.functionalTotal,
                                  }
                                : r
                            )
                          );
                        }}
                        onConnectionStatusChange={setQuickbooksConnectionStatus}
                        isClientOfAccountant={true}
                        clientEmail={clientEmail}
                      />
                      {SHOW_WAVE_BUTTON && (
                        <ShareToWave
                          reportId={report.id}
                          spreadsheetId={accountantData.spreadsheetId}
                          wave={report.wave}
                          isConnected={waveConnectionStatus}
                          onStatusUpdate={(newStatus) => {
                            setReports((prevReports) =>
                              prevReports.map((r) =>
                                r.id === report.id
                                  ? {
                                      ...r,
                                      wave: {
                                        ...r.wave,
                                        ...newStatus,
                                      },
                                      functionalTotal:
                                        newStatus.functionalTotal,
                                    }
                                  : r
                              )
                            );
                          }}
                          onConnectionStatusChange={setWaveConnectionStatus}
                          isClientOfAccountant={true}
                          clientEmail={clientEmail}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {totalPages > 1 && (
            <div className="pagination">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
                disabled={currentPage === 1}
                className="pagination-button"
              >
                Previous
              </button>
              <span className="pagination-info">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(totalPages, prev + 1))
                }
                disabled={currentPage === totalPages}
                className="pagination-button"
              >
                Next
              </button>
            </div>
          )}
        </>
      ) : (
        <p>No reports available.</p>
      )}
    </div>
  );

  return (
    <div className="accountant-view-container">
      <button className="back-button" onClick={() => navigate("/process-info")}>
        <i className="fas fa-arrow-left"></i>
        Back to Dashboard
      </button>

      {isLoadingAccountantView ? (
        <div className="loading-spinner">
          <TailSpin color="#0033a0" height={50} width={50} />
        </div>
      ) : (
        <>
          <div className="client-header">
            <h3 className="client-name">
              {accountantData?.clientName || ""}
              <span className="client-email">
                {accountantData?.clientEmail
                  ? `(${accountantData.clientEmail})`
                  : ""}
              </span>
            </h3>
            {accountantData?.spreadsheetId && (
              <a
                href={`https://docs.google.com/spreadsheets/d/${accountantData.spreadsheetId}/edit`}
                target="_blank"
                rel="noopener noreferrer"
                className="spreadsheet-link"
              >
                <i className="fas fa-exclamation-triangle spreadsheet-icon"></i>
                Live expenses spreadsheet and user settings
              </a>
            )}
          </div>
          {renderReports()}
          {renderNotesModal()} {/* Added the modal here */}
        </>
      )}
    </div>
  );
};

export default AccountantView;
