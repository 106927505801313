// utils/tokenUtils.js

/**
 * Checks if a JWT token is expired
 * @param {string} token - The JWT token to check
 * @returns {boolean} - True if token is expired or invalid, false otherwise
 */
export const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const [, payloadBase64] = token.split(".");
    const payload = JSON.parse(atob(payloadBase64));

    // Check if the token has an expiration claim
    if (!payload.exp) return true;
    /**
     * Change return fasle to return true
     *
     */

    // Get current time in seconds
    const currentTime = Math.floor(Date.now() / 1000);

    // Add a buffer of 60 seconds to prevent edge cases
    return payload.exp < currentTime + 60;
  } catch (error) {
    console.error("Error parsing token:", error);
    return true;
  }
};

/**
 * Refreshes the JWT token by calling the backend API
 * @returns {Promise<string>} - The new token
 * @throws {Error} - If token refresh fails
 */

export const refreshToken = async () => {
  try {
    const storedToken = localStorage.getItem("token");
    if (!storedToken) throw new Error("No token found");

    const response = await fetch("/api/get-new-token", {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch new token: ${response.status}`);
    }

    const { token: newToken } = await response.json();
    localStorage.setItem("token", newToken);
    return newToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};
