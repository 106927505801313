import React, { useState, useRef } from "react";
import { uploadToGooglePhotos } from "./cameraPhotosPicker";
import "../styles/CameraSelector.css";

const FileUploader = ({ onClose, onPhotosSelected, onError }) => {
  const [status, setStatus] = useState("ready");
  const fileInputRef = useRef(null);

  const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB max file size

  const handleFileSelect = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Check file size first
    if (file.size > MAX_FILE_SIZE) {
      onError?.(
        `File is too large (${(file.size / (1024 * 1024)).toFixed(
          1
        )}MB). Please select an image under 20MB.`
      );
      return;
    }

    try {
      setStatus("processing");

      // Determine compression level based on file size
      let compressionQuality;
      if (file.size > 10 * 1024 * 1024) {
        // > 10MB
        compressionQuality = 0.3;
      } else if (file.size > 5 * 1024 * 1024) {
        // > 5MB
        compressionQuality = 0.4;
      } else {
        compressionQuality = 0.5;
      }

      const blob = await optimizeImage(file, compressionQuality);
      const mediaItem = await uploadToGooglePhotos(blob);
      onPhotosSelected([mediaItem]);
      onClose();
    } catch (error) {
      console.error("Upload error:", error);
      onError?.(error.message);
      setStatus("ready");
    }
  };

  const optimizeImage = async (file, quality = 0.5) => {
    const img = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    return new Promise((resolve, reject) => {
      img.onload = () => {
        try {
          // Adaptive dimensions based on file size
          let MAX_WIDTH = 1000;
          let MAX_HEIGHT = 1500;

          if (file.size > 10 * 1024 * 1024) {
            MAX_WIDTH = 800;
            MAX_HEIGHT = 1200;
          }

          let width = img.width;
          let height = img.height;

          const ratio = Math.min(MAX_WIDTH / width, MAX_HEIGHT / height);
          if (ratio < 1) {
            width *= ratio;
            height *= ratio;
          }

          canvas.width = width;
          canvas.height = height;

          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = "high";
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                console.log(
                  `Compressed size: ${(blob.size / (1024 * 1024)).toFixed(2)}MB`
                );
                resolve(blob);
              } else {
                reject(new Error("Failed to compress image"));
              }
            },
            "image/jpeg",
            quality
          );
        } catch (error) {
          reject(error);
        }
      };

      img.onerror = () => {
        reject(new Error("Failed to load image"));
      };

      img.src = URL.createObjectURL(file);
    });
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button onClick={onClose} className="close-xc">
          ×
        </button>

        <div className="camera-container">
          {status === "ready" ? (
            <div className="file-select-area">
              <input
                ref={fileInputRef}
                type="file"
                accept="image/jpeg,image/png,image/heic"
                onChange={handleFileSelect}
                style={{ display: "none" }}
              />
              <button
                className="file-upload-button"
                onClick={() => fileInputRef.current?.click()}
              >
                <i className="fas fa-upload"></i>
                Select Receipt Image
              </button>
              <div className="file-info">
                Supported formats: JPEG, PNG, HEIC
                <br />
                Maximum file size: 4.5MB
              </div>
            </div>
          ) : (
            <div className="file-select-area">
              <div className="processing-status">
                <i className="fas fa-spinner fa-spin"></i>
                <span>Processing photo...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
