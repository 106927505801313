import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";

const InviteAcceptance = ({ user }) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);

  // Add a cleanup function
  const cleanupInviteStorage = () => {
    localStorage.removeItem("pendingInviteToken");
    localStorage.removeItem("returnPath");
  };

  useEffect(() => {
    const processInvite = async () => {
      try {
        const authToken = localStorage.getItem("token");

        if (!authToken || !user) {
          if (token) {
            localStorage.setItem("pendingInviteToken", token);
            localStorage.setItem("returnPath", `/invite/${token}`);
          }
          navigate("/");
          return;
        }

        setIsProcessing(true);
        console.log("Processing invite with token:", token);

        const inviteResponse = await axios.post(
          `/api/accountant/accept-invite/${token}`,
          {},
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );

        if (inviteResponse.data.success) {
          cleanupInviteStorage(); // Clean up all invite-related storage
          setIsProcessing(false);
          navigate("/process-info", {
            state: { message: "Successfully accepted invite" },
          });
        }
      } catch (error) {
        console.error(
          "Invite processing error:",
          error.response?.data || error
        );
        cleanupInviteStorage(); // Also clean up on error
        setIsProcessing(false);
        setError(
          error.response?.data?.error ||
            "Failed to process invite. Please contact support if this persists."
        );
      }
    };

    if (token) {
      processInvite();
    } else {
      // If we somehow got here without a token, clean up and redirect
      cleanupInviteStorage();
      navigate("/process-info");
    }

    // Cleanup on component unmount
    return () => {
      cleanupInviteStorage();
    };
  }, [token, navigate, user]);

  if (error) {
    return (
      <div className="p-4 max-w-md mx-auto mt-8">
        <div className="bg-red-50 border-l-4 border-red-400 p-4">
          <div className="text-red-700 font-medium">
            Error Processing Invite
          </div>
          <div className="text-red-600">{error}</div>
        </div>
        <button
          onClick={() => navigate("/process-info")}
          className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors"
        >
          Return to Dashboard
        </button>
      </div>
    );
  }

  return (
    <div className="p-4 max-w-md mx-auto mt-8 text-center">
      {isProcessing ? (
        <div className="flex flex-col items-center gap-4">
          <TailSpin color="#0033a0" height={40} width={40} />
          <p className="text-lg">Processing invite...</p>
        </div>
      ) : (
        <p className="text-lg">Successfully processed! Redirecting...</p>
      )}
    </div>
  );
};

export default InviteAcceptance;
