import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  GoogleMap,
  Autocomplete,
  DirectionsService,
  DirectionsRenderer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import "../styles/GoogleMapsDistance.css";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 43.6532,
  lng: -79.3832,
};

const GoogleMapsDistance = ({
  isOpen,
  onClose,
  onDistanceCalculated,
  unit,
}) => {
  const mapRef = useRef(null);
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const modalRef = useRef(null);

  const [fromPlace, setFromPlace] = useState(null);
  const [toPlace, setToPlace] = useState(null);
  const [showFromInfo, setShowFromInfo] = useState(false);
  const [showToInfo, setShowToInfo] = useState(false);
  const [directionsResult, setDirectionsResult] = useState(null);
  const [directionError, setDirectionError] = useState("");
  const [calculatedValues, setCalculatedValues] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [calculatedDistance, setCalculatedDistance] = useState(null);
  const [calculatedDuration, setCalculatedDuration] = useState(null);
  const [startY, setStartY] = useState(null);
  const [currentY, setCurrentY] = useState(null);
  const [toAutocompleteOptions, setToAutocompleteOptions] = useState({});

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (mapRef.current) {
        if (window.google && window.google.maps) {
          window.google.maps.event.clearInstanceListeners(mapRef.current);
        }
        mapRef.current = null;
      }
      if (fromRef.current) fromRef.current = null;
      if (toRef.current) toRef.current = null;
    };
  }, []);

  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setFromPlace(null);
      setToPlace(null);
      setDirectionsResult(null);
      setDirectionError("");
      setIsCalculating(false);
      setCalculatedDistance(null);
      setCalculatedDuration(null);
      setToAutocompleteOptions({});
      setCalculatedValues(null);
      setShowFromInfo(false);
      setShowToInfo(false);

      try {
        const fromInput = document.querySelector(
          'input[placeholder="Enter starting location"]'
        );
        const toInput = document.querySelector(
          'input[placeholder="Enter destination"]'
        );
        if (fromInput) fromInput.value = "";
        if (toInput) toInput.value = "";
      } catch (error) {
        console.warn("Could not reset input fields:", error);
      }
    }
  }, [isOpen]);

  // Memoized map options
  const mapOptions = useMemo(
    () => ({
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      gestureHandling: "greedy",
      minZoom: 2,
      maxZoom: 20,
    }),
    []
  );

  // Memoized handlers
  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleMapError = useCallback((error) => {
    console.error("Google Maps Error:", error);
    setDirectionError("Failed to load map. Please try again.");
  }, []);

  const handleFromPlaceChanged = useCallback(() => {
    const place = fromRef.current?.getPlace();
    if (place?.geometry?.location) {
      setFromPlace(place);

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      // Create bounds centered on the selected location
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(lat - 0.15, lng - 0.15),
        new window.google.maps.LatLng(lat + 0.15, lng + 0.15)
      );

      // Set bias options based on the selected location
      setToAutocompleteOptions({
        bounds,
        strictBounds: false, // Allow results outside bounds but prefer within
      });
    }
  }, []);

  const handleAutocompleteLoad = useCallback((autocomplete, type) => {
    if (type === "from") {
      fromRef.current = autocomplete;
    } else {
      toRef.current = autocomplete;
    }

    if (autocomplete && autocomplete.setOptions) {
      autocomplete.setOptions({
        fields: ["address_components", "geometry", "name", "formatted_address"],
        types: ["address"], // Changed this to just "address"
      });
    }
  }, []);

  const FromSearchBox = useMemo(
    () => (
      <Autocomplete
        onLoad={(autocomplete) => handleAutocompleteLoad(autocomplete, "from")}
        onPlaceChanged={handleFromPlaceChanged}
        options={{
          fields: [
            "address_components",
            "geometry",
            "name",
            "formatted_address",
          ],
          types: ["establishment", "geocode"],
        }}
      >
        <input
          type="text"
          placeholder="Enter starting location"
          className="map-input"
        />
      </Autocomplete>
    ),
    [handleAutocompleteLoad, handleFromPlaceChanged]
  );

  const ToSearchBox = useMemo(
    () => (
      <Autocomplete
        onLoad={(autocomplete) => handleAutocompleteLoad(autocomplete, "to")}
        onPlaceChanged={() => {
          const place = toRef.current?.getPlace();
          if (place?.geometry?.location) {
            setToPlace(place);
            if (fromPlace) {
              setIsCalculating(true);
              setDirectionsResult(null);
            }
          }
        }}
        options={{
          ...toAutocompleteOptions,
          fields: [
            "address_components",
            "geometry",
            "name",
            "formatted_address",
          ],
          types: ["establishment", "geocode"],
        }}
      >
        <input
          type="text"
          placeholder="Enter destination"
          className="map-input"
        />
      </Autocomplete>
    ),
    [fromPlace, toAutocompleteOptions, handleAutocompleteLoad]
  );

  const onDirectionsCallback = useCallback(
    (response, status) => {
      setIsCalculating(false);
      if (status === "OK" && fromPlace && toPlace) {
        setDirectionsResult(response);

        const distanceMeters = response.routes[0].legs[0].distance.value;
        const duration = response.routes[0].legs[0].duration.text;
        let distance;
        if (unit === "mi") {
          distance = (distanceMeters / 1609.34).toFixed(2);
        } else {
          distance = (distanceMeters / 1000).toFixed(2);
        }

        setCalculatedDistance(distance);
        setCalculatedDuration(duration);

        const origin = encodeURIComponent(fromPlace.formatted_address);
        const destination = encodeURIComponent(toPlace.formatted_address);
        const mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving`;

        let cityName = "";
        if (toPlace.address_components) {
          const cityComponent = toPlace.address_components.find(
            (comp) =>
              comp.types.includes("locality") ||
              comp.types.includes("administrative_area_level_1")
          );
          if (cityComponent) {
            cityName = cityComponent.long_name;
          }
        }

        setCalculatedValues({
          distance,
          mapUrl,
          destinationName: toPlace.name || toPlace.formatted_address,
          city: cityName,
        });

        setDirectionError("");
      } else {
        console.error("Directions request failed due to: " + status);
        setDirectionError(
          "Unable to calculate directions. Please try different locations."
        );
      }
    },
    [fromPlace, toPlace, unit]
  );

  // Updated touch handlers
  const handleTouchStart = useCallback((e) => {
    setStartY(e.touches[0].clientY);
  }, []);

  const handleTouchMove = useCallback(
    (e) => {
      if (!startY) return;

      const touchY = e.touches[0].clientY;
      setCurrentY(touchY);

      const deltaY = touchY - startY;
      if (deltaY > 0 && modalRef.current) {
        modalRef.current.style.webkitTransform = `translateY(${deltaY}px)`;
        modalRef.current.style.transform = `translateY(${deltaY}px)`;
      }
    },
    [startY] // Depends on startY
  );

  const handleTouchEnd = useCallback(
    (e) => {
      if (!startY || !currentY) return;

      const deltaY = currentY - startY;
      if (deltaY > 100) {
        onClose();
      } else if (modalRef.current) {
        modalRef.current.style.webkitTransform = "translateY(0)";
        modalRef.current.style.transform = "translateY(0)";
      }

      setStartY(null);
      setCurrentY(null);
    },
    [startY, currentY, onClose] // Depends on startY, currentY, and onClose
  );

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      modalElement.addEventListener("touchstart", handleTouchStart, {
        passive: false,
      });
      modalElement.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
      modalElement.addEventListener("touchend", handleTouchEnd, {
        passive: false,
      });

      return () => {
        modalElement.removeEventListener("touchstart", handleTouchStart);
        modalElement.removeEventListener("touchmove", handleTouchMove);
        modalElement.removeEventListener("touchend", handleTouchEnd);
      };
    }
  }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

  const handleUseDistance = useCallback(() => {
    if (calculatedValues) {
      onDistanceCalculated(calculatedValues);
      onClose();
    }
  }, [calculatedValues, onDistanceCalculated, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className={`map-modal-backdrop ${isOpen ? "visible" : ""}`}
      onClick={onClose}
      style={{
        WebkitBackdropFilter: "blur(5px)",
        backdropFilter: "blur(5px)",
      }}
    >
      <div
        ref={modalRef}
        className={`map-modal-slide-up ${isOpen ? "visible" : ""}`}
        onClick={(e) => e.stopPropagation()}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          WebkitTransform: "translateY(0)",
          transform: "translateY(0)",
          WebkitTransition: "-webkit-transform 0.3s ease-out",
          transition: "transform 0.3s ease-out",
        }}
      >
        <div className="map-modal-handle" />

        <div className="map-modal-header">
          <h4 className="map-modal-title">
            <i className="fas fa-map-marker-alt"></i>
            &nbsp;Calculate Trip Distance
          </h4>
          <button className="map-modal-close" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="map-modal-content">
          <div className="map-search-container">
            <div className="map-input-group">
              <label className="mileage-label">Starting Point</label>
              <div className="map-input-wrapper">
                <i className="fas fa-circle text-green-500"></i>
                {FromSearchBox}
              </div>
            </div>

            <div className="map-input-group">
              <label className="mileage-label">Destination</label>
              <div className="map-input-wrapper">
                <i className="fas fa-map-marker-alt text-red-500"></i>
                {ToSearchBox}
              </div>
            </div>
          </div>

          {directionError && (
            <div className="mileage-alert">
              <i className="fas fa-exclamation-circle"></i>
              {directionError}
            </div>
          )}

          {calculatedDistance && (
            <div className="route-info">
              <div className="route-info-detail">
                <i className="fas fa-road"></i>
                <span className="route-distance">
                  {calculatedDistance} {unit}
                </span>
              </div>
              <div className="route-info-detail">
                <i className="fas fa-clock"></i>
                <span>{calculatedDuration}</span>
              </div>
            </div>
          )}

          <div className="map-container">
            {isCalculating && (
              <div className="map-loading-overlay">
                <div className="map-loading-spinner">
                  <i className="fas fa-circle-notch fa-spin"></i>
                  <span>Calculating route...</span>
                </div>
              </div>
            )}

            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={handleMapLoad}
              onError={handleMapError}
              options={mapOptions}
            >
              {fromPlace && !directionsResult && (
                <Marker
                  position={fromPlace.geometry.location}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                  }}
                  onClick={() => setShowFromInfo(true)}
                >
                  {showFromInfo && (
                    <InfoWindow onCloseClick={() => setShowFromInfo(false)}>
                      <div>
                        <strong>Starting Point</strong>
                        <p>{fromPlace.formatted_address.split(",")[0]}</p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              )}

              {toPlace && !directionsResult && (
                <Marker
                  position={toPlace.geometry.location}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                  }}
                  onClick={() => setShowToInfo(true)}
                >
                  {showToInfo && (
                    <InfoWindow onCloseClick={() => setShowToInfo(false)}>
                      <div>
                        <strong>Destination</strong>
                        <p>{toPlace.formatted_address.split(",")[0]}</p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              )}

              {fromPlace && toPlace && isCalculating && (
                <DirectionsService
                  options={{
                    destination:
                      typeof toPlace.geometry.location === "function"
                        ? toPlace.geometry.location().toJSON()
                        : toPlace.geometry.location.toJSON(), // Ensure this is a LatLngLiteral
                    origin:
                      typeof fromPlace.geometry.location === "function"
                        ? fromPlace.geometry.location().toJSON()
                        : fromPlace.geometry.location.toJSON(), // Ensure this is a LatLngLiteral
                    travelMode: "DRIVING",
                  }}
                  callback={onDirectionsCallback}
                />
              )}

              {directionsResult && (
                <DirectionsRenderer
                  directions={directionsResult}
                  options={{
                    suppressMarkers: false,
                    polylineOptions: {
                      strokeColor: "#0033a0",
                      strokeWeight: 5,
                    },
                  }}
                />
              )}
            </GoogleMap>
          </div>

          <div className="map-actions">
            <button
              type="button"
              onClick={onClose}
              className="mileage-button mileage-button-secondary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleUseDistance}
              className="mileage-button mileage-button-primary"
              disabled={!calculatedDistance}
            >
              Use This Distance
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMapsDistance;
