import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  createPickerSession,
  pollSession,
  fetchSelectedMediaItems,
} from "./googlePhotosPicker";
import "../styles/PhotoSelector.css";

const PHOTO_LIMIT = process.env.REACT_APP_NEXT_PUBLIC_PHOTO_LIMIT || 100;

const createSessionManager = () => {
  let currentRequest = null;
  return {
    isProcessing: () => !!currentRequest,
    getCurrentRequest: () => currentRequest,
    startRequest: () => {
      currentRequest = {};
      return currentRequest;
    },
    endRequest: (request) => {
      if (request === currentRequest) {
        currentRequest = null;
      }
    },
  };
};

const PhotoSelector = ({ onClose, onPhotosSelected, onError }) => {
  const [pickerUri, setPickerUri] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [status, setStatus] = useState("initializing");
  const [hasStartedSelection, setHasStartedSelection] = useState(false);
  const [validPhotoCount, setValidPhotoCount] = useState(0);
  const [selectionError, setSelectionError] = useState(null);
  const [exceededLimit, setExceededLimit] = useState(false);
  const checkSelectionRef = useRef(null);
  const sessionManager = useRef(createSessionManager());
  const visibilityTimeoutRef = useRef(null);
  const [initMessage, setInitMessage] = useState(
    "Initializing photo selector..."
  );

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "visible") {
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current);
      }

      const storedState = localStorage.getItem("photoPickerState");
      if (storedState) {
        try {
          const { sessionId: storedSessionId } = JSON.parse(storedState);
          if (storedSessionId === sessionId) {
            visibilityTimeoutRef.current = setTimeout(() => {
              if (checkSelectionRef.current) {
                checkSelectionRef.current();
              }
            }, 1000);
          }
        } catch (error) {
          console.warn("Error parsing stored picker state:", error);
        }
      }
    }
  }, [sessionId]);

  useEffect(() => {
    if (sessionId && hasStartedSelection) {
      localStorage.setItem(
        "photoPickerSession",
        JSON.stringify({
          sessionId,
          startTime: Date.now(),
          lastPoll: Date.now(),
        })
      );
    }
  }, [sessionId, hasStartedSelection]);

  useEffect(() => {
    const savedSession = localStorage.getItem("photoPickerSession");
    if (savedSession) {
      const { sessionId: savedId, startTime } = JSON.parse(savedSession);
      if (Date.now() - startTime < 30 * 60 * 1000) {
        setSessionId(savedId);
        setHasStartedSelection(true);
        setStatus("waiting");
      } else {
        localStorage.removeItem("photoPickerSession");
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem("photoPickerSession");
      localStorage.removeItem("photoPickerState");
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const initSession = async () => {
      if (pickerUri || sessionId || hasStartedSelection) {
        console.log("Session already initialized, skipping...");
        return;
      }

      try {
        setInitMessage("Initializing photo selector...");

        const { sessionId: newSessionId, pickerUri: newPickerUri } =
          await createPickerSession();

        // Add a delay and message before opening the tab
        setInitMessage("Opening Google Photos in a new tab...");
        await new Promise((resolve) => setTimeout(resolve, 1500));

        setSessionId(newSessionId);
        setPickerUri(newPickerUri);

        const sessionState = {
          sessionId: newSessionId,
          startTime: Date.now(),
          pickerUri: newPickerUri,
        };

        localStorage.setItem("photoPickerState", JSON.stringify(sessionState));
        setHasStartedSelection(true);
        setStatus("waiting");

        // Open in new tab
        const newTab = document.createElement("a");
        newTab.href = newPickerUri;
        newTab.target = "_blank";
        newTab.rel = "noopener noreferrer";
        localStorage.setItem("originalTab", window.location.href);
        newTab.click();
      } catch (error) {
        console.error("Failed to create session:", error);
        if (error.response && error.response.status === 403) {
          onError?.(
            "To use this feature, please ensure Google Photos is properly set up:\n\n" +
              "1. Install Google Photos from your device's app store\n" +
              "2. Open Google Photos and sign in\n" +
              "3. Go to Google Photos settings\n" +
              "4. Enable 'Backup & Sync'\n" +
              "5. Allow backup over cellular data (recommended)\n\n" +
              "Your photos will be available in ExpenseBot after syncing completes."
          );
        } else if (error.response && error.response.status === 404) {
          onError?.(
            "No photos found. Please install Google Photos, enable 'Backup & Sync' in settings, and wait for your photos to sync."
          );
        } else {
          onError?.("Failed to start photo selection. Please try again.");
        }
        onClose();
      }
    };
    initSession();
  }, [onClose, onError, pickerUri, sessionId, hasStartedSelection]);

  useEffect(() => {
    let timeoutId;
    const POLL_INTERVAL = 5000; // 5 seconds for all devices
    let lastPollTime = Date.now();

    const checkSelection = async () => {
      if (sessionManager.current.isProcessing()) {
        console.log("Polling already in progress, skipping...");
        return;
      }

      if (!sessionId) return;

      const currentRequest = sessionManager.current.startRequest();

      try {
        lastPollTime = Date.now();

        const session = await pollSession(sessionId);
        console.log("Poll session response:", session);

        if (currentRequest !== sessionManager.current.getCurrentRequest()) {
          console.log("Request superseded, discarding results");
          return;
        }

        if (session.mediaItemsSet) {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          const mediaItems = await fetchSelectedMediaItems(sessionId);

          if (currentRequest !== sessionManager.current.getCurrentRequest()) {
            console.log(
              "Request superseded after fetching media items, discarding results"
            );
            return;
          }

          console.log("Raw media items from Picker:", mediaItems);

          if (!Array.isArray(mediaItems) || mediaItems.length === 0) {
            throw new Error("No media items selected or invalid response.");
          }

          if (mediaItems.length > PHOTO_LIMIT) {
            setExceededLimit(true);
            setValidPhotoCount(mediaItems.length);
            setStatus("error");
            setSelectionError(
              `You've selected ${mediaItems.length} photos, which exceeds the limit of ${PHOTO_LIMIT}. Please make a new selection with fewer photos.`
            );
            return;
          }

          const photoItems = mediaItems.filter((item) => {
            if (item.type !== "PHOTO") {
              console.warn(`Skipping non-photo item with type: ${item.type}`);
              return false;
            }

            if (!item.id || !item.baseUrl || !item.mimeType) {
              console.warn(
                "Skipping item missing required fields:",
                JSON.stringify(
                  {
                    hasId: !!item.id,
                    hasBaseUrl: !!item.baseUrl,
                    hasMimeType: !!item.mimeType,
                  },
                  null,
                  2
                )
              );
              return false;
            }

            return true;
          });

          if (photoItems.length === 0) {
            setSelectionError(
              "No valid photos found in your selection. Please try again."
            );
            setStatus("error");
            return;
          }

          const transformedPhotos = photoItems.map((item) => ({
            id: item.id,
            baseUrl: item.baseUrl,
            mimeType: item.mimeType,
            filename: item.filename || `photo_${item.id}.jpg`,
            mediaMetadata: {
              creationTime:
                item.mediaMetadata?.creationTime || new Date().toISOString(),
            },
          }));

          if (photoItems.length > 0) {
            setSelectionError(null);
            setValidPhotoCount(photoItems.length);
            setStatus("processing");

            console.log(`Processing ${photoItems.length} photos...`);

            setTimeout(() => {
              onPhotosSelected(transformedPhotos);
              onClose();
            }, 1000);
          }
        } else {
          const timeSinceLastPoll = Date.now() - lastPollTime;
          const adjustedInterval = Math.max(
            0,
            POLL_INTERVAL - timeSinceLastPoll
          );
          timeoutId = setTimeout(checkSelection, adjustedInterval);
        }
      } catch (error) {
        console.error("Error processing selected photos:", error);
        onError?.(
          error.message || "Error processing selected photos. Please try again."
        );
        onClose();
      } finally {
        sessionManager.current.endRequest(currentRequest);
      }
    };

    checkSelectionRef.current = checkSelection;
    document.addEventListener("visibilitychange", handleVisibilityChange);
    checkSelection();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current);
      }

      const storedState = localStorage.getItem("photoPickerState");
      if (storedState) {
        try {
          const { popup } = JSON.parse(storedState);
          if (popup?.checkIntervalId) clearInterval(popup.checkIntervalId);
          if (popup?.timeoutId) clearTimeout(popup.timeoutId);
        } catch (error) {
          console.warn("Error cleaning up popup timers:", error);
        }
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      localStorage.removeItem("photoPickerState");
    };
  }, [sessionId, onClose, onError, onPhotosSelected, handleVisibilityChange]);

  const handleRetry = async () => {
    try {
      setPickerUri(null);
      setStatus("initializing");
      const { sessionId: newSessionId, pickerUri: newPickerUri } =
        await createPickerSession();
      setHasStartedSelection(false);
      setExceededLimit(false);
      setSelectionError(null);
      setSessionId(newSessionId);
      setPickerUri(newPickerUri);

      // Immediately start the new selection
      const sessionState = {
        sessionId: newSessionId,
        startTime: Date.now(),
        pickerUri: newPickerUri,
      };

      localStorage.setItem("photoPickerState", JSON.stringify(sessionState));
      setHasStartedSelection(true);
      setStatus("waiting");

      // Open in new tab
      const newTab = document.createElement("a");
      newTab.href = newPickerUri;
      newTab.target = "_blank";
      newTab.rel = "noopener noreferrer";
      newTab.click();
    } catch (error) {
      console.error("Failed to create new session:", error);
      onError?.("Failed to restart photo selection. Please try again.");
      onClose();
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        {!pickerUri ? (
          <div className="loading-indicator">
            <i className="fas fa-spinner fa-spin"></i>
            <span>{initMessage}</span>
          </div>
        ) : (
          <div className="status-container">
            {status === "error" ? (
              <div className="error-status">
                <i className="fas fa-exclamation-circle"></i>
                <div className="status-message">
                  <strong>{selectionError}</strong>
                  {exceededLimit && (
                    <div className="exceeded-limit-actions">
                      <button onClick={handleRetry} className="retry-button">
                        Make New Selection
                      </button>
                      <p className="limit-note">
                        Please select {PHOTO_LIMIT} photos or fewer.
                      </p>
                    </div>
                  )}
                  {!exceededLimit && <p>You can try selecting photos again.</p>}
                </div>
              </div>
            ) : (
              <div className="status-message">
                <i
                  className={
                    status === "processing"
                      ? "fas fa-spinner fa-spin"
                      : "fas fa-sync-alt"
                  }
                ></i>
                <div className="message-content">
                  <strong>
                    {status === "processing"
                      ? `Processing ${validPhotoCount} selected photos...`
                      : "Waiting for your selection..."}
                  </strong>
                  {status !== "processing" && (
                    <>
                      <p>
                        This page will update automatically once you're done.
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}

            {status !== "processing" && (
              <button onClick={onClose} className="cancel-button">
                Cancel Selection
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoSelector;
