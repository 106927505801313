import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/faq.css";

const QuickBooksIntegration = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/");
  };

  return (
    <div className="faq-container">
      <header className="faq-header">
        <button className="back-button" onClick={handleBackClick}>
          ← Back
        </button>
        <div className="logo-container">
          <a href="/">
            <img
              src="./eblogo_2.png"
              alt="ExpenseBot Logo"
              className="faq-logo"
            />
          </a>
        </div>
        <div className="faq-title-container">
          <h1 className="faq-title">ExpenseBot + QuickBooks Integration</h1>
        </div>
      </header>

      <section className="section">
        <h2 className="section-header">End-to-End Expense Management</h2>
        <p className="styled-paragraph">
          Connecting ExpenseBot to QuickBooks provides an end-to-end solution
          that eliminates the biggest pain points for both users and finance
          teams. For finance teams, this includes solving issues like data
          entry, categorization, grouping, and compliance by attaching links to
          receipt photos. For users, it simplifies and accelerates expense
          report creation, making it easier to get reimbursed.
        </p>
      </section>

      <section className="section">
        <h2 className="section-header">Key Benefits</h2>

        <div className="subsection">
          <h3 className="subsection-title">For Finance Teams</h3>
          <ul className="styled-list">
            <li>
              <strong>Streamlined Submissions:</strong> Expense reports created
              in ExpenseBot can be submitted to QuickBooks with one click,
              either by the user or the accounting professional managing the
              books.
            </li>
            <li>
              <strong>Customer-Specific Tagging:</strong> Automatically tags
              expenses with customer names when tagged in ExpenseBot and matched
              to QuickBooks, simplifying invoicing and tracking expenses tied to
              clients.
            </li>
            <li>
              <strong>Enhanced Compliance:</strong> Every expense report
              submitted includes a cloud-based link to the full report, complete
              with photos of each receipt. This ensures compliance and makes
              audits or financial reviews easy and transparent.
            </li>
          </ul>
        </div>

        <div className="subsection">
          <h3 className="subsection-title">For Users</h3>
          <ul className="styled-list">
            <li>
              <strong>Simple Expense Reports:</strong> Streamlines the process
              of gathering receipts, creating reports, and submitting them to
              QuickBooks without onerous data entry.
            </li>
            <li>
              <strong>Comprehensive Grouping:</strong> Automatically categorizes
              expenses, and organizes expenses by trips, clients, or projects,
              ensuring reports are detailed and easy to manage.
            </li>
            <li>
              <strong>Faster Reimbursements:</strong> By simplifying the process
              from start to finish, users' expense reports are processed faster,
              leading to quicker reimbursements.
            </li>
          </ul>
        </div>
      </section>

      <section className="section">
        <h2 className="section-header">How It Works</h2>
        <p className="styled-paragraph">
          Submitting an expense report to QuickBooks is simple and efficient
          with ExpenseBot. Once users create their report, they can submit it
          with a single click. ExpenseBot then:
        </p>
        <ul className="styled-list">
          <li>
            Automatically logs the report as a Bill in QuickBooks, complete with
            detailed categorization and taxes.
          </li>
          <li>
            Includes a cloud-based link in QuickBooks to the full expense report
            and photos of each receipt, ensuring transparency and ease of
            reference.
          </li>
          <li>
            Uses AI to match QuickBooks expense categories with those used in
            ExpenseBot, ensuring expenses are accurately categorized.
          </li>
        </ul>
      </section>

      <section className="section">
        <h2 className="section-header">Watch Our Tutorials</h2>
        <div className="video-container">
          <div className="video">
            <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>
              ExpenseBot - QuickBooks Intro
            </h3>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/uKhZNpNnHwI?rel=0"
              title="QuickBooks and ExpenseBot Intro"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video">
            <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>
              ExpenseBot - QuickBooks Walk Through
            </h3>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/J2RBZTQnWus?rel=0"
              title="Sending Reports to QuickBooks Walk Through"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video">
            <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>
              What is a Functional Total?
            </h3>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/PX_foWzqiTg?rel=0"
              title="Functional Total"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>

      <section className="section">
        <h2 className="section-header">
          Start Simplifying Your Expense Management
        </h2>
        <p className="styled-paragraph">
          Experience the seamless integration of ExpenseBot with QuickBooks and
          transform the way you manage expenses. Whether you're a user looking
          to simplify your reports or a finance team aiming for efficient
          workflows, ExpenseBot is here to help.
        </p>
        <button className="cta-button" onClick={() => navigate("/signup")}>
          Get Started Today
        </button>
      </section>
    </div>
  );
};

export default QuickBooksIntegration;
