import React, { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    const pathParts = window.location.pathname.split("/");
    if (pathParts[1] === "invite" && pathParts[2]) {
      const inviteToken = pathParts[2];
      localStorage.setItem("pendingInviteToken", inviteToken);
      localStorage.setItem("returnPath", `/invite/${inviteToken}`);
      console.log("Stored invite token on load:", inviteToken);
    }
  }, []);

  const handleGoogleLogin = () => {
    window.location.href = "/api/auth?skipConsent=true";
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Section */}
      <header className="relative px-4 pt-6 pb-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <img
            src="/eblogo_2.png"
            alt="ExpenseBot"
            className="h-16 mx-auto mb-8"
          />

          <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            Snap. Submit. Done.
          </h1>

          <p className="max-w-xl mx-auto mb-8 text-xl text-gray-600">
            Transform expense tracking from hours to seconds using tools you
            already know. No new apps, no learning curve.
          </p>

          {/* Video Section */}
          <div className="relative mx-auto overflow-hidden bg-gray-100 rounded-xl aspect-video max-w-4xl mb-8">
            <iframe
              className="absolute inset-0 w-full h-full"
              src="https://www.youtube.com/embed/7FvypLFqhuM?autoplay=1&mute=1&rel=0"
              title="ExpenseBot Demo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </header>

      {/* Device Compatibility Banner */}
      <div className="bg-gray-50 py-3 border-y border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-8">
            <div className="flex items-center text-gray-600">
              <svg className="w-5 h-5 mr-2" viewBox="0 0 384 512">
                <path
                  fill="currentColor"
                  d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                />
              </svg>
              Works on iPhone
            </div>
            <div className="flex items-center text-gray-600">
              <svg className="w-5 h-5 mr-2" viewBox="0 0 505.145 505.145">
                <g fill="currentColor">
                  <path d="M68.541,164.715h-1.294c-16.588,0-30.113,13.568-30.113,30.113v131.107 c0,16.61,13.525,30.134,30.113,30.134h1.316c16.588,0,30.113-13.568,30.113-30.134V194.827 C98.654,178.283,85.108,164.715,68.541,164.715z" />
                  <path d="M113.085,376.54c0,15.229,12.446,27.632,27.675,27.632h29.574v70.817 c0,16.631,13.568,30.156,30.113,30.156h1.294c16.61,0,30.156-13.546,30.156-30.156v-70.817h41.33v70.817 c0,16.631,13.611,30.156,30.156,30.156h1.273c16.609,0,30.134-13.546,30.134-30.156v-70.817h29.595 c15.207,0,27.654-12.403,27.654-27.632V169.525H113.085V376.54z" />
                  <path d="M322.041,43.983l23.491-36.26c1.51-2.287,0.841-5.414-1.467-6.903 c-2.286-1.51-5.414-0.884-6.903,1.467l-24.353,37.512c-18.27-7.485-38.676-11.691-60.226-11.691 c-21.571,0-41.934,4.206-60.247,11.691l-24.31-37.512c-1.489-2.351-4.638-2.977-6.946-1.467c-2.308,1.488-2.977,4.616-1.467,6.903 l23.512,36.26c-42.387,20.773-70.968,59.924-70.968,104.834c0,2.761,0.173,5.479,0.41,8.175h280.053 c0.237-2.696,0.41-5.414,0.41-8.175C393.009,103.907,364.406,64.756,322.041,43.983z M187.655,108.911 c-7.442,0-13.482-5.997-13.482-13.46c0-7.463,6.04-13.439,13.482-13.439c7.485,0,13.482,5.975,13.482,13.439 S195.097,108.911,187.655,108.911z M317.49,108.911c-7.442,0-13.482-5.997-13.482-13.46c0-7.463,6.04-13.439,13.482-13.439 c7.463,0,13.46,5.975,13.46,13.439C330.95,102.914,324.953,108.911,317.49,108.911z" />
                  <path d="M437.876,164.715h-1.251c-16.588,0-30.156,13.568-30.156,30.113v131.107 c0,16.61,13.59,30.134,30.156,30.134h1.273c16.609,0,30.134-13.568,30.134-30.134V194.827 C468.011,178.283,454.464,164.715,437.876,164.715z" />
                </g>
              </svg>
              Works on Android
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <section className="py-16 bg-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid gap-8 md:grid-cols-3">
            {/* No App Needed */}
            <div className="p-6 transition-all bg-white rounded-2xl hover:shadow-lg text-center">
              <div className="flex items-center justify-center h-48 mb-6">
                <img
                  src="/phone3.png"
                  alt="Submit receipts with your phone"
                  className="object-contain w-full h-full"
                />
              </div>
              <h3 className="mb-2 text-xl font-semibold text-gray-900">
                No App Required
              </h3>
              <p className="text-gray-600">
                Use your phone's normal camera - no downloads, no updates, no
                hassle. Just snap and submit.
              </p>
            </div>

            {/* Built on Google Sheets */}
            <div className="p-6 transition-all bg-white rounded-2xl hover:shadow-lg text-center">
              <div className="flex items-center justify-center h-48 mb-6">
                <img
                  src="/middleimage.png"
                  alt="Google Sheets integration"
                  className="object-contain w-full h-full"
                />
              </div>
              <h3 className="mb-2 text-xl font-semibold text-gray-900">
                Built on Google Sheets
              </h3>
              <p className="text-gray-600">
                - Work in the familiar environment of Google Sheets - no
                training needed. Your team already knows how to use it, making
                adoption instant and effortless.
              </p>
            </div>

            {/* Your Data, Your Control */}
            <div className="p-6 transition-all bg-white rounded-2xl hover:shadow-lg text-center">
              <div className="flex items-center justify-center h-48 mb-6">
                <img
                  src="/3rdphoto.png"
                  alt="Secure data control"
                  className="object-contain w-full h-full"
                />
              </div>
              <h3 className="mb-2 text-xl font-semibold text-gray-900">
                Your Data, Your Control
              </h3>
              <p className="text-gray-600">
                All data securely stored in your Google Drive with complete
                transparency and ownership.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Currency Support Banner */}
      <div className="bg-gray-50 py-3 border-y border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-8">
            <div className="text-gray-600 text-sm font-medium text-center">
              Multi-Currency Support
            </div>
            <div className="flex items-center space-x-6">
              <span className="text-xl">🇺🇸</span>
              <span className="text-xl">🇨🇦</span>
              <span className="text-xl">🇪🇺</span>
              <span className="text-xl">🇬🇧</span>
              <span className="text-xl">🇦🇺</span>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <section className="py-16 bg-gradient-to-r from-blue-50 to-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="mb-12 text-3xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-yellow-400">
            Built for Everyone
          </h2>

          <div className="grid gap-8 md:grid-cols-3">
            {/* For Individuals */}
            <div className="p-8 bg-white rounded-2xl hover:shadow-lg transition-all">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-2xl font-semibold text-gray-900">
                For Individuals
              </h3>
              <p className="text-gray-600 mb-4">
                Perfect for self-employed, gig workers, and personal expense
                tracking.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Keep business expenses organized for tax time</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Easily share records with your accountant</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>All support documents ready for review/audit</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Track personal tax receipts (medical, donations)</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Access from anywhere with Google Drive</span>
                </li>
              </ul>
            </div>

            {/* Business Teams Section */}
            <div className="p-8 bg-white rounded-2xl hover:shadow-lg transition-all">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-2xl font-semibold text-gray-900">
                For Business Teams
              </h3>
              <p className="text-gray-600 mb-4">
                Submit expenses in seconds using tools you already know. No
                training needed.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Use your phone's camera - no app needed</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Automatic mileage expense tracking</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Automatic trip and project grouping</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Multi-currency support built-in</span>
                </li>
              </ul>
            </div>

            {/* Finance Teams Section */}
            <div className="p-8 bg-white rounded-2xl hover:shadow-lg transition-all">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-2xl font-semibold text-gray-900">
                For Finance Teams
              </h3>
              <p className="text-gray-600 mb-4">
                Streamline processing with automation and ensure compliance with
                ease.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Every expense line backed by receipt photos</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Audit-ready documentation and tracking</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Smart receipt data extraction</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>Direct ERP/accounting integration</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Integration Partners Banner */}
      <div className="bg-gray-50 py-3 border-y border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-8">
            <div className="text-gray-600 text-sm font-medium text-center">
              Integrates With
            </div>
            <div className="flex items-center space-x-6">
              <img
                src="/qblogo2.png"
                alt="QuickBooks"
                className="h-8 w-auto object-contain" // Added object-contain
              />
              <img
                src="/wavelogo.png"
                alt="Wave"
                className="h-10 w-auto object-contain" // Increased height and added object-contain
              />
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <section className="py-16 bg-gradient-to-r from-blue-50 to-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="mb-12 text-3xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-yellow-400">
            Simple, Transparent Pricing
          </h2>
          <script
            async
            src="https://js.stripe.com/v3/pricing-table.js"
          ></script>
          <stripe-pricing-table
            pricing-table-id="prctbl_0QRcK8ABrmtPgfeVFLMyUxPP"
            publishable-key="pk_live_1ABrmtPgfeVnuSAIytHIFtemaBw23COIp8nCpzbQYSYr50tUlYKgL6BM51fn2wnyrGI9tX0PPyHzW7SA86QcQImem00zk4jRlbw"
          ></stripe-pricing-table>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-2xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Simplify Your Expenses?
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Start today with your Google account - no new software to learn
          </p>
        </div>
      </section>

      {/* Login Button - Floating */}
      <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-sm px-4">
        <button
          onClick={handleGoogleLogin}
          className="flex items-center justify-center w-full px-6 py-3 text-lg font-medium text-gray-700 transition-all bg-white rounded-lg shadow-lg hover:shadow-xl hover:-translate-y-1"
        >
          <img src="/g-logo.png" alt="Google" className="w-6 h-6 mr-3" />
          Login with Google
        </button>
      </div>

      {/* Footer */}
      <footer className="py-8 text-center text-gray-600">
        <p className="text-sm">
          By signing up, you agree to the ExpenseBot{" "}
          <a href="/privacy" className="text-blue-600 hover:underline">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="/terms" className="text-blue-600 hover:underline">
            Terms of Service
          </a>
          . <br />
          Have questions? Check out our{" "}
          <a href="/faq" className="text-blue-600 hover:underline">
            FAQ
          </a>
          .
        </p>
      </footer>
    </div>
  );
};

export default Home;
