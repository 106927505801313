import React, { useState, useEffect } from "react";
import axios from "axios";
import GoogleMapsDistance from "./GoogleMapsDistance";
import "../styles/MileageTracker.css";

const MileageTracker = ({ isOpen, onClose, processInfo, onSuccess }) => {
  // Add new state for settings
  const [settings, setSettings] = useState({
    unit: processInfo?.mileageUnit || "mi",
    allowancePerUnit: processInfo?.mileageRate || 0,
  });

  useEffect(() => {
    if (processInfo?.mileageUnit || processInfo?.mileageRate) {
      setSettings({
        unit: processInfo.mileageUnit || "mi",
        allowancePerUnit: processInfo.mileageRate || 0,
      });
    }
  }, [processInfo]);

  useEffect(() => {
    if (isOpen) {
      // Reset trip state to initial values
      setTrip({
        date: new Date().toISOString().split("T")[0],
        name: "",
        miles: "",
        mapLink: "",
        notes: "",
        city: "",
      });
      // Clear any existing errors
      setError("");
    }
  }, [isOpen]);

  const [trip, setTrip] = useState({
    date: new Date().toISOString().split("T")[0],
    name: "",
    miles: "",
    mapLink: "",
    notes: "",
    city: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);

  const showError = (errorMessage) => {
    setError(errorMessage);
    const modalContent = document.querySelector(".mileage-modal-content");
    if (modalContent) {
      modalContent.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const openSpreadsheetSettings = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gidSettings}`,
      "_blank"
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    showError("");
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "/api/processMileage",
        {
          date: trip.date,
          description: trip.name,
          distance: parseFloat(trip.miles),
          city: trip.city,
          notes: trip.notes,
          mapLink: trip.mapLink,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        if (response.data.settings) {
          // Update local settings state
          setSettings(response.data.settings);
        }
        onClose();
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (response.data.settingsUrl) {
          showError(
            <div className="mileage-alert-content">
              <p>{response.data.error}</p>
              <button
                onClick={() => window.open(response.data.settingsUrl, "_blank")}
                className="mileage-alert-link"
              >
                Configure Mileage Settings
              </button>
            </div>
          );
        } else {
          showError(response.data.error);
        }
      }
    } catch (error) {
      if (error.response?.data?.settingsUrl) {
        showError(
          <div className="mileage-alert-content">
            <p>{error.response.data.error}</p>
            <button
              onClick={() =>
                window.open(error.response.data.settingsUrl, "_blank")
              }
              className="mileage-alert-link"
            >
              Configure Mileage Settings
            </button>
          </div>
        );
      } else {
        showError("Failed to save mileage expense. Please try again.");
      }
      console.error("Error saving mileage:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCalculateClick = () => {
    setTrip((prev) => ({
      ...prev,
      miles: "",
      mapLink: "",
      name: "",
      city: "",
    }));
    setIsMapModalOpen(false);
    // Force a complete reset before reopening
    requestAnimationFrame(() => {
      setIsMapModalOpen(true);
    });
  };

  const handleDistanceCalculated = (result) => {
    setTrip((prev) => ({
      ...prev,
      miles: result.distance,
      mapLink: result.mapUrl,
      name: prev.name || result.destinationName || prev.name,
      city: prev.city || result.city || prev.city,
    }));
    setIsMapModalOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className="mileage-modal-backdrop">
      <div className="mileage-modal-wrapper">
        <div className="mileage-modal-header">
          <h2 className="mileage-modal-title">
            <i className="fas fa-car"></i>
            Add Mileage
          </h2>
          <button className="mileage-modal-close" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="mileage-modal-content">
          {/* Settings Info */}
          <div className="mileage-settings-card">
            <div className="mileage-settings-header">
              <span className="mileage-settings-title">Current Settings</span>
              <button
                onClick={openSpreadsheetSettings}
                className="mileage-settings-edit"
              >
                <i className="fas fa-external-link-alt"></i>
                Edit Settings
              </button>
            </div>
            <div className="mileage-settings-item">
              <div className="mileage-settings-label">Current Rate</div>
              <div className="mileage-settings-value">
                ${settings.allowancePerUnit.toFixed(3)}/{settings.unit}
              </div>
            </div>
          </div>

          {error && (
            <div className="mileage-alert">
              <i className="fas fa-exclamation-circle"></i>
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="mileage-form">
            <div className="mileage-form-grid">
              <div className="mileage-form-group">
                <label htmlFor="date" className="mileage-label">
                  Trip Date *
                </label>
                <input
                  id="date"
                  type="date"
                  required
                  className="mileage-input"
                  value={trip.date}
                  onChange={(e) => setTrip({ ...trip, date: e.target.value })}
                />
              </div>
              <div className="mileage-form-group">
                <label htmlFor="name" className="mileage-label">
                  Trip Description *
                </label>
                <input
                  id="name"
                  type="text"
                  required
                  className="mileage-input"
                  placeholder="e.g., Client Meeting NYC"
                  value={trip.name}
                  onChange={(e) => setTrip({ ...trip, name: e.target.value })}
                />
              </div>
            </div>

            <div className="mileage-form-grid">
              <div className="mileage-form-group">
                <label htmlFor="miles" className="mileage-label">
                  Distance ({settings.unit}) *
                </label>
                <div className="mileage-distance-input">
                  <input
                    id="miles"
                    type="number"
                    required
                    step="0.01"
                    min="0"
                    className="mileage-input"
                    placeholder={`Enter distance in ${settings.unit}`}
                    value={trip.miles}
                    onChange={(e) =>
                      setTrip({ ...trip, miles: e.target.value })
                    }
                  />
                  <button
                    type="button"
                    onClick={handleCalculateClick} // Use the new handler
                    className="mileage-map-button"
                  >
                    <i className="fas fa-map-marker-alt"></i>
                    Calculate
                  </button>
                </div>
              </div>
              <div className="mileage-form-group">
                <label htmlFor="city" className="mileage-label">
                  City
                </label>
                <input
                  id="city"
                  type="text"
                  className="mileage-input"
                  placeholder="Optional"
                  value={trip.city}
                  onChange={(e) => setTrip({ ...trip, city: e.target.value })}
                />
              </div>
            </div>

            <div className="mileage-form-group mileage-notes">
              <label htmlFor="notes" className="mileage-label">
                Notes
              </label>
              <textarea
                id="notes"
                className="mileage-textarea"
                placeholder="Add any additional details about this trip"
                value={trip.notes}
                onChange={(e) => setTrip({ ...trip, notes: e.target.value })}
                rows={2}
              />
            </div>

            {/*
            {trip.mapLink && (
              <div className="mileage-map-link-container">
                <a
                  href={trip.mapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mileage-map-link"
                >
                  <i className="fas fa-external-link-alt"></i>
                  View Route on Google Maps
                </a>
              </div>
            )}
 */}
            <div className="mileage-actions">
              <button
                type="button"
                onClick={onClose}
                className="mileage-button mileage-button-secondary"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="mileage-button mileage-button-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="mileage-button-loading">
                    <i className="fas fa-spinner fa-spin"></i>
                    Saving...
                  </span>
                ) : (
                  "Save Mileage Expense"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <GoogleMapsDistance
        isOpen={isMapModalOpen}
        onClose={() => setIsMapModalOpen(false)}
        onDistanceCalculated={handleDistanceCalculated}
        unit={settings.unit}
      />
    </div>
  );
};

export default MileageTracker;
